import React, { useRef, useEffect, useContext, useState } from 'react'
import {
  CHeader,
  CImg,
  CButton,
  CHeaderNav,
  CHeaderNavItem,
  CHeaderNavLink,
  CSubheader,
  CBreadcrumbRouter,
  CLabel,
  CInput,
  CTextarea,
  CFormGroup
} from '@coreui/react'
import './header.scss'

import { boxCessionSocieteLinks, boxDonationsSuccessionLinks, bilanPatrimonialLinks, boxFinanciersLinks, everywhereLinks, boxImmobilierLinks, lienFicheThematique, lienPEA, lienFormulairePEA, lienCompteTitres, lienFormulaireCompteTitres, lienAV, lienFormulaireAV, lienCAPI, lienFormulaireCAPI, lienFormulaireIS, lienIS, lienFormulaireComparaison, lienResultatFinancierComparaison, lienResultatTransmissionComparaison, lienSimulateurCessionSociete, lienIFI, lienUsufruitViager, lienUsufruitEconomique, lienUsufruitTemporaire, lienDonsFamiliauxExoneres, lienDonationsEntreprise, lienAutresDonations, lienSuccession, lienBilanPatrimonial, lienFiscaliteRevenus, lienEmprunt, lienPlusValueImmobiliere, lienListeClient, lienFicheClient, lienPageAccueilSimulabox, lienDonation } from '../routes'

import { useHistory, useLocation, useParams } from 'react-router-dom'
import profil from '../assets/icons/svg/user.svg'
import profilBlanc from '../assets/icons/svg/userWhite.svg'
import { LayoutContext } from '../App2'
import { UtilsContext } from '../store/context/utils'
import { logout } from '../store/actions/utils/login'
import { SimulateurContext } from '../store/context/simulateur'
import { PropFiContext } from '../store/context/propfi'
import { InputCurrency, InputSelect, InputText } from '../components/input'
import { restApiUri } from '../config'
import { displayOrHideCalculatrice, updateClientList, updateShowPersonnalisationSimulationState, updateShowShortcutSimulationState, updateSimulation } from '../store/actions/simulateur'
import Hypothese from '../components/simulateur/hypothese'
import { TheHeaderContext } from '../store/context/theHeader'
import { DataControlContext } from '../store/context/dataControl'
import { fetchClientList } from '../store/actions/utils/dataControl'
import Loading from '../components/loader'
import { addToast, deleteToast } from '../store/actions/utils/toast'
import { OrdinaryTooltip } from '../components/InfoBulle/Tooltip/Tooltip';
import BoutonPersonnalisationInformationSimulation from '../assets/images/personnalisation-information-simulation.svg';
import BoutonValidationPersonnalisationInformationSimulation from '../assets/images/validation-personnalisation-information-simulation.svg';
import BoutonFermeturePersonnalisationInformationSimulation from '../assets/images/fermeture-personnalisation-information-simulation.svg'
import BoutonDeconnexion from '../assets/images/bouton-deconnexion.svg'
import { COULEUR_FOND_SIMULATEUR, formatToUniqueId, messageSuccesEnregistrementSimulation } from '../utils'
import Switch from '../components/input/Switch/Switch'
import InfoBulle from '../components/InfoBulle/InfoBulle'
import InputDate, { isCompleteDate } from '../components/input/InputDate'
import { wordAsCapitalize } from '../views/clients'
import ArrowBackFT from '../assets/images/arrow-to-go-back-ft.svg';
import SupportClientIcon from '../assets/images/SUPPORT_ICON.svg';
import UtilisateurIcon from '../assets/images/bouton-utilisateur.svg';
import Gadget from '../assets/images/gadget.svg';
import ModalConfirmation from '../components/ModalConfirmation/ModalConfirmation'
import Shortcuts from '../components/Shortcuts/Shortcuts'
import Abbove from '../components/Abbove/Abbove'
import ModalRightSidebar from '../components/ModalRightSidebar/ModalRightSidebar'
import AbboveDecouverte from '../components/Abbove/AbboveDecouverte'

const TheHeader = ({ socket }) => {
  const history = useHistory()
  const location = useLocation()
  const params = useParams();

  const { utilsState, utilsDispatch } = useContext(UtilsContext);
  const { clientListState, headerDispatch } = useContext(TheHeaderContext);
  const {
    dataControlDispatch,
    profilUtilisateurState,
    setProfilUtilisateurState,
    successionState,
    setSuccessionState,
    fiscaliteState,
    setFiscaliteState,
    ifiState,
    setIfiState,
    autreState,
    headerMenuIndex,
    setHeaderMenuIndex,
    pvImmobiliereState,
    donsFamiliauxExoneresState,
    setDonsFamiliauxExoneresState,
    donationDutreilState,
    setDonationDutreilState,
    autreDonationState,
    setAutreDonationState,
    ficheClientState,
    setFicheClientState
  } = useContext(DataControlContext);
  const { simulateurState, simulateurDispatch, showShortcutSimulation } = useContext(SimulateurContext);
  const { propfiState } = useContext(PropFiContext)
  const { simulation: sim } = simulateurState;
  const idCli = sim && sim.clientId ? sim.clientId : null;
  const { situation_familial: situationSuccession, id_client, client } = successionState.config;
  const avecImportationFicheClientSuccession = id_client && client;

  const controller = new AbortController()
  const signal = controller.signal

  const [clients, setClients] = useState([])
  const [cmpStep, setCmpStep] = useState(simulateurState.cmpStep)
  const showFormulairePersonnalisationSimulation = simulateurState?.showFormulairePersonnalisationSimulation || false;
  const [pfStep, setPfStep] = useState(propfiState.pfStep)

  const [routes2, setRoutes2] = useState([]);
  const [hasInvestissementsFinanciersBox, setHasInvestissementsFinanciersBox] = useState(JSON.parse(localStorage.getItem('user'))?.menus.find((element) => parseInt(element.ordre) === 1));
  const [hasCessionSocieteBox, setHasCessionSocieteBox] = useState(JSON.parse(localStorage.getItem('user'))?.menus.find((element) => parseInt(element.ordre) === 2));

  const is_page_cmp = location.pathname.includes('comparaisons') &&
    (location.pathname.includes('formulaire') || location.pathname.includes('resultat_financier') || location.pathname.includes('resultat_transmission'))
  const is_page_propfi = location.pathname.includes('proposition') &&
    (location.pathname.includes('formulaire') || location.pathname.includes('resultatsFI'))
  const is_page_succession = location.pathname.includes('succession')
  const is_page_pvImmo = location.pathname.includes('pv-immobiliere');

  // Uniquement pour la Cession de société
  const stepCessionPage = location.pathname.includes('identite_actionnaire') ||
    location.pathname.includes('projet_cession') ||
    location.pathname.includes('operation_avant_cession') ||
    location.pathname.includes('resultat_synthetique') ||
    location.pathname.includes('resultat_detaille')

  const pageCession = location.pathname.includes('cession') && stepCessionPage && (!location.pathname.includes('aide') && !location.pathname.includes('notice'));
  const is_page_cession = pageCession && JSON.parse(localStorage.getItem('user'))?.menus.find((element) => parseInt(element.ordre) === 2);

  /*const show_form = is_page_cession ||
    location.pathname.includes('comparaisons/') ||
    location.pathname.includes('/proposition-financiere') ||
    location.pathname.includes('simulateurs/') ||
    location.pathname.includes('usuf-viager') ||
    location.pathname.includes('usuf-temporaire') ||
    location.pathname.includes('exonere') ||
    location.pathname.includes('ordinaire') ||
    location.pathname.includes('entreprise') ||
    location.pathname.includes('bilan') ||
    location.pathname.includes('succession') ||
    location.pathname.includes('fiscalite-revenu') ||
    location.pathname.includes('emprunt') ||
    location.pathname.includes('usufruit-economique') ||
    location.pathname.includes('/ifi') ||
    location.pathname.includes('/pv-immobiliere');*/

  const is_result_pea = location.pathname === '/simulateurs/pea'
  const is_result_ct = location.pathname === '/simulateurs/ct'
  const is_result_av = location.pathname === '/simulateurs/av'
  const is_result_capi = location.pathname === '/simulateurs/cp'
  const is_result_is = location.pathname === '/simulateurs/is'

  var user = JSON.parse(localStorage.getItem('user'))

  const header = useRef()
  const menu_onglet = useRef()

  const { layoutState, setLayoutState } = useContext(LayoutContext)
  const { menuLevel, bilanPatrimonial, ficheClient } = layoutState;
  const conjoint1 = ficheClient?.conjoints?.[0] || {};

  // Concernant le MODAL de la fiche thématique
  const [nomPrenomClient, setNomPrenomClient] = useState("")
  const [category, setCategory] = useState('CS');
  const [modalVisualisation, setModalVisualisation] = useState(false);
  const [pptLoading, setPPTLoading] = useState(false);
  const [documentTypeToVisualise, setDocumentTypeToVisualise] = useState('PPT');
  const [anchorEl, setAnchorEl] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [infoClient, setInfoClient] = useState({
    id: null,
    nom: "",
    fonction: "",
    telephone: "",
    email: "",
    adresse: ""
  })

  const [showInfo, setShowInfo] = useState(false);
  const [pvImmobiliereSteps, setPvImmobiliereSteps] = useState([
    "Type de cession",
    null,
    null,
    null,
    null
  ])

  const togglePopup = () => {
    setIsOpen(!isOpen);
  }

  const toggleVisualisation = () => { setModalVisualisation(!modalVisualisation) };

  const handleChoiceTypeDocument = (event) => { setAnchorEl(event.currentTarget) };

  const handleClose = () => { setAnchorEl(null) };

  const hideLoader = () => { setPPTLoading(false) };

  const cessionSteps = [
    "Identité des actionnaires",
    "Projet de cession de société",
    "Opérations avant cession",
    "Résultats synthétiques",
    "Résultats détaillés",
  ]

  const prenomConjoint1Succession = successionState?.beneficiaire?.conjoint1?.nom?.toString().toUpperCase() || `${[1, "1", 0, "0"].includes(situationSuccession) ? 'Conjoint' : 'Partenaire'} 1`;
  const prenomConjoint2Succession = successionState?.beneficiaire?.conjoint2?.nom?.toString().toUpperCase() || `${[1, "1", 0, "0"].includes(situationSuccession) ? 'Conjoint' : 'Partenaire'} 2`;

  const defaultSuccessionSteps = [
    "CONFIGURATION",
    "PATRIMOINE",
    "HÉRITIERS, LÉGATAIRES ET BÉNÉFICIAIRES",
    "DÉVOLUTION ET FISCALITÉ",
    "COMPARAISON"
  ]

  // const [successionSteps, setSuccessionSteps] = useState(defaultSuccessionSteps);

  // useEffect(() => {
  //   if (avecImportationFicheClientSuccession) {
  //     let steps = JSON.parse(JSON.stringify(defaultSuccessionSteps));

  //     if (situationSuccession != 1)
  //       steps = steps.filter((element) => element !== 'COMPARAISON');

  //     setSuccessionSteps(steps);
  //   }
  //   else {
  //     setSuccessionSteps(["CONFIGURATION"]);
  //   }
  // }, [avecImportationFicheClientSuccession, situationSuccession])

  useEffect(() => {
    const prenomConjoint1 = conjoint1?.prenom || "";
    const nomConjoint1 = conjoint1?.nom || "";
    const prenomNomConjoint1 = `${wordAsCapitalize(prenomConjoint1)}${prenomConjoint1 !== "" ? " " : ""}${nomConjoint1.toUpperCase()}`;

    setNomPrenomClient(prenomNomConjoint1);
  }, [JSON.stringify(conjoint1)])

  const isPropositionFinancierePage = location.pathname.includes('/proposition-financiere');

  const propositionFinanciereSteps = [
    <div>Sélection de <br />l'investisseur</div>,
    <div>Répartition des <br />investissements</div>,
    <div>Proposition <br />d'investissement</div>
  ]

  useEffect(() => {
    const { cession_directe, cession_indirecte, cession_sci_ir } = pvImmobiliereState.config;
    const currentMenuItems = [...pvImmobiliereSteps]; //JSON.parse(JSON.stringify(pvImmobiliereSteps));
    let compteur = 0;

    if (cession_directe) {
      currentMenuItems[1] = "Cession directe";
      compteur++;
    }
    else {
      currentMenuItems[1] = null;
    }

    if (cession_indirecte) {
      currentMenuItems[2] = "Cession indirecte";
      compteur++;
    }
    else {
      currentMenuItems[2] = null;
    }

    if (cession_sci_ir) {
      currentMenuItems[3] = <div>Cession de parts<br /> de SCI à l'IR</div>;
      compteur++;
    }
    else {
      currentMenuItems[3] = null;
    }

    if (compteur >= 1) {
      currentMenuItems[4] = compteur === 1 ? "Résultats synthétiques" : "Comparaison";
    }
    else {
      currentMenuItems[4] = null;
    }

    setPvImmobiliereSteps(currentMenuItems);
  }, [pvImmobiliereState.config])

  // className selon le SIMULATEUR
  const menuClassName = "d-flex";
  const nomSimulationClassName = is_page_cession ? "col-lg-4 col-md-4" : "col-lg-4";
  const clientClassName = is_page_cession ? "col-lg-3 col-md-3" : "col-lg-3";
  const commentaireClassName = is_page_cession ? "col-lg-5 col-md-5" : "col-lg-5";

  const currentCessionMenuLevel = menuLevel.cs;

  useEffect(() => {
    setCmpStep(simulateurState.cmpStep)
    setPfStep(propfiState.pfStep)
  }, [simulateurState, propfiState])

  useEffect(() => {
    if (header.current) {
      header.current.classList.add("c-header-fixed")
    }
  }, [location])

  useEffect(() => {
    clientsFetch()
    return () => {
      controller.abort()
    }
  }, [])

  useEffect(() => {
    // Liens communs peu importe les BOXES du client en question
    setRoutes2((prevState) => {
      return [
        ...prevState,
        ...everywhereLinks
      ]
    });

    // S'il y a la BOX Investissements financiers
    if (hasInvestissementsFinanciersBox) {
      setRoutes2((prevState) => {
        return [
          ...prevState,
          ...boxFinanciersLinks
        ]
      });
    }

    // S'il y a la BOX Cession de société
    if (hasCessionSocieteBox) {
      setRoutes2((prevState) => {
        return [
          ...prevState,
          ...boxCessionSocieteLinks
        ]
      });
    }

    // S'il y a la BOX Donations et Successions
    if (true) {
      setRoutes2((prevState) => {
        return [
          ...prevState,
          ...boxDonationsSuccessionLinks
        ]
      });
    }

    if (true) {
      setRoutes2((prevState) => {
        return [
          ...prevState,
          ...bilanPatrimonialLinks
        ]
      });
    }

    if (true) {
      setRoutes2((prevState) => {
        return [
          ...prevState,
          ...boxImmobilierLinks
        ]
      })
    }
  }, []);

  useEffect(() => {
    clientsFetch();
    simulateurDispatch(updateSimulation({ identifiant_simulation: "brouillon", commentaire: "" }))
  }, [clientListState])

  const clientsFetch = () => {
    const user = JSON.parse(localStorage.getItem('user'))
    fetch(`${restApiUri}/api/client/findClient?recherche=&poleId=` + user.pole.id + "&userId=" + user.id, {
      method: "GET",
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        //Authorization: `Bearer ${localStorage.getItem("token")}`
      },
      signal
    })
      .then(resp => resp.json())
      .then(data => {
        if (data.message) {
        } else if (data.mesClientsPole.length > 0 || data.autreClientPole.length > 0) {
          let customers = data.mesClientsPole.concat(data.autreClientPole);

          customers.sort(function (a, b) {

            let keyA = a.nom === '' ? a.prenom : a.nom;
            let keyB = b.nom === '' ? b.prenom : b.nom;

            if (keyA < keyB) return -1
            if (keyA > keyB) return 1

            return 0
          })

          setClients(customers);
          dataControlDispatch(fetchClientList(customers));
          simulateurDispatch(updateClientList(customers))
        }

      }).catch(e => console.log(e))
  }

  useEffect(() => {
      if (simulateurState?.clientList?.length > 0) {
        setClients(simulateurState?.clientList);
      }
  }, [simulateurState?.clientList])

  const handleClickOutside = (event) => {
    const userPopupElement = document.getElementById('user-popup');
    const userPopupElementContent = document.getElementById('user-popup-content');

    if (userPopupElement && !userPopupElement.contains(event.target) && !userPopupElementContent?.contains(event.target)) {
      setIsOpen(false);
    }

    const shortcutPopupElement = document.getElementById('shortcut-simulation-content');
    if (shortcutPopupElement && !shortcutPopupElement.contains(event.target)) {
      simulateurDispatch(updateShowShortcutSimulationState({ showShortcutSimulation: false }))
    }

    const clientPopupElement = document.getElementById('show-formulaire-personnalisation-simulation');
    const clientPopupElementContent = document.getElementById('show-formulaire-personnalisation-simulation-content');

    if (clientPopupElement && !clientPopupElement.contains(event.target) && !clientPopupElementContent?.contains(event.target)) {
      simulateurDispatch(updateShowPersonnalisationSimulationState({
        showFormulairePersonnalisationSimulation: false
      }))
    }

  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const deconnexion = () => {
    const id = JSON.parse(localStorage.getItem('user'))?.id
    socket.emit('disconnectUser', { userId: id});
    simulateurDispatch(displayOrHideCalculatrice({
      showCalculatrice: false
    }))
    utilsDispatch(logout())
    history.push('/login')
    window.location.reload();
  }

  const Mailto = ({ email, subject = '', body = '', children }) => {
    let params = subject || body ? '?' : '';
    if (subject) params += `subject=${encodeURIComponent(subject)}`;
    if (body) params += `${subject ? '&' : ''}body=${encodeURIComponent(body)}`;

    return <a href={`mailto:${email}${params}`}>{children}</a>;
  }

  const style = {
    transform: isOpen ? "rotate(-90deg)" : "",
    transition: 'transform 150ms ease',
    marginLeft: 12
  };

  // Informations
  const { id, nom, fonction, telephone, email, adresse, avecMentionLegale, societe, mentionLegale } = profilUtilisateurState;

  // Changement des formulaires sur le Profil utilisateur
  const handleFormChange = (event, prop) => {
    let { value } = event.target;
    const profil = { ...profilUtilisateurState };

    if (prop === 'avecMentionLegale') value = !profil.avecMentionLegale;

    profil[prop] = value;

    setProfilUtilisateurState(profil);
  }

  // Récupération d'une info Utilisateur
  const getInfoClient = () => {
    let userId = null;

    if (localStorage && localStorage.getItem('user')) {
      userId = JSON.parse(localStorage.getItem('user')).id;
    }

    fetch(`${restApiUri}/api/detailClient/findDetailByUser/?userId=${userId}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${localStorage.getItem("token")}`
      },
    })
      .then((response) => response.json())
      .then((response) => {
        if (response?.detail) {
          // const cli = { ...infoClient };
          const cli = { ...profilUtilisateurState };
          cli.id = response.id;
          cli.nom = response.detail.nom || "";
          cli.fonction = response.detail.fonction || "";
          cli.telephone = response.detail.telephone || "";
          cli.email = response.detail.email || "";
          cli.adresse = response.detail.adresse || "";
          cli.societe = response.detail.societe || "";
          cli.avecMentionLegale = response.detail.avecMentionLegale !== undefined ? response.detail.avecMentionLegale : true;
          cli.mentionLegale = response.detail.mentionLegale || "";

          setProfilUtilisateurState(cli);
        }
      })
      .catch((e) => {
        setLoading(false);
        console.error(e);
      })
      .finally(() => setLoading(false))
  }

  // Ajout ou modifications des infos utilisateurs
  const addInfoUtilisateur = () => {
    let userData = {};

    if (localStorage && localStorage.getItem('user')) {
      userData = JSON.parse(localStorage.getItem('user'))
    }

    const userId = userData &&
      userData.id ? userData.id : null;

    setLoading(true);
    fetch(`${restApiUri}/api/detailClient${id !== null ? `/${id}` : ""}`, {
      method: id !== null ? 'PUT' : 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${localStorage.getItem("token")}`
      },
      body: JSON.stringify({
        userId: userId,
        detail: {
          nom: nom,
          fonction: fonction,
          telephone: telephone,
          email: email,
          adresse: adresse,
          societe: societe,
          avecMentionLegale: avecMentionLegale,
          mentionLegale: mentionLegale
        }
      }),
      cache: 'no-cache'
    })
      .then((response) => {
        if (response) {
          getInfoClient()
          utilsDispatch(addToast({ type: 'success', title: 'Succès', description: messageSuccesEnregistrementSimulation(), autoDelete: true }))
        }
      })
      .catch((e) => {
        setLoading(false)
        console.error(e);
      })
      .finally(() => setLoading(false))
  }

  useEffect(() => {
    getInfoClient();
  }, [])

  const formulaireInfoUtilisateur = {
    fontSize: 13,
    width: '100%',
    margin: '0 auto',
    marginBottom: 14
  }

  // Année fiscale Fiscalité des revenus
  const anneesFiscales = [
    {
      label: 2023,
      value: '2023-12-12'
    },
    {
      label: 2022,
      value: '2022-12-12'
    },
    {
      label: 2021,
      value: '2021-12-12'
    },
    {
      label: 2020,
      value: '2020-12-12'
    }
  ];

  // Année fiscale IFI
  const anneesFiscalesIFI = [];


  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  for (let index = currentYear; index >= 2021; index--) {
      anneesFiscalesIFI.push({
      value: index,
      label: `IFI ${index}`
    })
  }

  const currentLink = location.pathname;
  const [nomSimulateur, setNomSimulateur] = useState("");

  useEffect(() => {
    let nom = "";

    // PEA
    if (currentLink.includes(lienPEA) || currentLink.includes(lienFormulairePEA)) nom = "PEA";

    // Compte titres
    if (currentLink.includes(lienCompteTitres) || currentLink.includes(lienFormulaireCompteTitres)) nom = "Compte titres";

    // Assurance-vie
    if (currentLink.includes(lienAV) || currentLink.includes(lienFormulaireAV)) nom = "Assurance-vie";

    // Contrat de capitalisation
    if (currentLink.includes(lienCAPI) || currentLink.includes(lienFormulaireCAPI)) nom = "Contrat de capitalisation";

    // Société IS
    if (currentLink.includes(lienIS) || currentLink.includes(lienFormulaireIS)) nom = "Société à l'IS";

    // Cession de société
    if (currentLink.includes(lienSimulateurCessionSociete)) nom = "Cession de société";

    // Comparaisons
    if (currentLink.includes(lienFormulaireComparaison) || currentLink.includes(lienResultatFinancierComparaison) || currentLink.includes(lienResultatTransmissionComparaison)) nom = "Comparaison de structures";

    // IFI
    if (currentLink.includes(lienIFI)) nom = "Impôt sur la fortune immobilière";

    // Liste des clients
    if (currentLink === lienListeClient) nom = "Liste de vos clients";

    // Fiche Client
    if (currentLink.includes(lienFicheClient)) nom = nomPrenomClient || "Fiche client";

    setNomSimulateur(nom);
  }, [currentLink, nomPrenomClient])

  // Manipulation en cas de simulation existante
  let currentPathname = location?.pathname;
  let pathNameAsArray = location?.pathname?.split('/');

  if (pathNameAsArray?.length > 0) {
    const length = pathNameAsArray.length;

    if (!isNaN(parseInt(pathNameAsArray[length - 1]))) {
      pathNameAsArray = pathNameAsArray.filter((element) => element !== "");
      pathNameAsArray.pop();
      currentPathname = "";

      pathNameAsArray.map((path, index) => {
        currentPathname += `/${path}`
      })
    }
  }
  // Fin manipulation

  const elementByPathname = routes2?.find((element) => { return currentPathname === element.path });
  const simulateurActif = nomSimulateur !== "" ? nomSimulateur : elementByPathname?.name || "";

  // Condition d'affichage des formulaires de personnalisation de SIMULATION
  const [conditionAffichageInfoClientExistant, setConditionAffichageInfoClientExistant] = useState(false);
  useEffect(() => {
    const conditionAffichageInfoClient = currentPathname.includes(lienFicheClient) && ![null, undefined].includes(ficheClientState.idClient);
    setConditionAffichageInfoClientExistant(conditionAffichageInfoClient);
  }, [ficheClientState.idClient, currentPathname])

  const show_form = [
    lienFormulairePEA,
    lienPEA,
    lienFormulaireCompteTitres,
    lienCompteTitres,
    lienFormulaireAV,
    lienAV,
    lienFormulaireCAPI,
    lienCAPI,
    lienFormulaireIS,
    lienIS,
    lienSimulateurCessionSociete,
    lienUsufruitViager,
    lienUsufruitTemporaire,
    lienDonsFamiliauxExoneres,
    lienDonationsEntreprise,
    lienAutresDonations,
    lienSuccession,
    lienBilanPatrimonial,
    lienFiscaliteRevenus,
    lienIFI,
    lienEmprunt,
    lienUsufruitEconomique,
    lienPlusValueImmobiliere
  ].includes(currentPathname) || location.pathname.includes('/comparaisons/') || location.pathname.includes('/proposition-financiere') || conditionAffichageInfoClientExistant;

  // Condition d'affichage du SUBHEADER
  const noSubheader = currentPathname === lienPageAccueilSimulabox;

  // Condition d'affichage des formulaires exceptionnels dans le HEADER
  const avecFormulaireExceptionnel = [lienBilanPatrimonial, lienFiscaliteRevenus, lienIFI].includes(currentPathname);

  const situationName = Number(bilanPatrimonial?.patrimoineGlobal?.situationFamiliale) === 1 ? 'Conjoint' : Number(bilanPatrimonial?.patrimoineGlobal?.situationFamiliale) === 2 ? 'Partenaire' : 'Client';

  // Les données de la SITUATION FAMILIALE DU BILAN PATRIMONIAL
  const situationFamilialeData = [
    {
      level: 1,
      label: "Global"
    },
    {
      level: 2,
      label: `${situationName} 1`
    },
    {
      level: 3,
      label: `${situationName} 2`
    }
  ];

  // Quand les informations du Bilan patrimonial changent
  const handleInformationsBilanPatrimonialChange = (event, prop) => {
    let { value } = event.target;
    const bp = JSON.parse(JSON.stringify(bilanPatrimonial));

    if (prop === 'affichage') value = parseInt(value, 10);

    if (prop === 'situationDu') {
      if (isCompleteDate(value)) {
        const message = "Veuillez indiquer la date du bilan patrimonial.";

        const content = utilsState?.toasts?.find((element) => element.description === message);
        if (content?.id) {
          utilsDispatch(deleteToast(content.id))
        }
      }
    }

    bp[prop] = value;

    setLayoutState((prevState) => {
      return {
        ...prevState,
        bilanPatrimonial: bp
      }
    });
  };

  const typeContenu = currentPathname.includes(lienListeClient) ? 'client' : currentPathname.includes(lienFicheThematique) ? 'fiche-thematique' : 'simulateur';
  const [visibleConfirmationMessage, setVisibleConfirmationMessage] = useState(false);
  const [currentData, setCurrentData] = useState(null);

  // Quand on confirme la Boîte de dialogue
  const confirmerChangementClient = () => {
    if (currentData) {
      // Changement global du Client
      simulateurDispatch(updateSimulation({ clientId: currentData.clientId || null }));

      // Pour la Succession
      if (currentPathname.includes(lienSuccession)) {
        const succ = JSON.parse(JSON.stringify(successionState));

        // Partie Client
        succ.config.id_client = currentData.clientId;
        succ.config.version_fiche_client = null;
        succ.config.client = null;
        succ.config.date_heure_importation_fiche_client = "";
        succ.config.import_fiche_client = 0;

        // Partie Bilan patrimonial
        succ.config.id_patrimonial = null;
        succ.config.date_heure_importation_bilan_patrimonial = "";
        succ.config.import_bilan_patrimonial = 0;
        succ.bilan_patrimonial = null;

        setSuccessionState(succ);
        setHeaderMenuIndex((prevState) => {
          return {
            ...prevState,
            succession: 0
          }
        })
      }

      // Pour les Dons familiaux exonérés
      if (currentPathname.includes(lienDonsFamiliauxExoneres)) {
        const don = JSON.parse(JSON.stringify(donsFamiliauxExoneresState));

        don.config.client = currentData.client || null;
        don.config.versionFicheClient = null;
        don.config.donateur = null;
        don.config.lienParente = 0;
        don.config.donataire = null;
        don.config.avecImport = 0;

        setDonsFamiliauxExoneresState(don);
      }

      // Pour les Donations d'entreprise
      if (currentPathname.includes(lienDonationsEntreprise)) {
        const donationEntreprise = JSON.parse(JSON.stringify(donationDutreilState));

        donationEntreprise.avec_import.client = currentData.client || null;
        donationEntreprise.avec_import.versionFicheClient = null;
        donationEntreprise.avec_import.donateur = 0;
        donationEntreprise.avec_import.lienParente = 0;
        donationEntreprise.avec_import.avecImport = 0;

        setDonationDutreilState(donationEntreprise);
      }

      // Pour les Autres donations
      if (currentPathname.includes(lienAutresDonations)) {
        const autreDonation = JSON.parse(JSON.stringify(autreDonationState));

        autreDonation.avec_import.client = currentData.client || null;
        autreDonation.avec_import.versionFicheClient = null;
        autreDonation.avec_import.donateur = 0;
        autreDonation.avec_import.lienParente = 0;
        autreDonation.avec_import.avecImport = 0;

        setAutreDonationState(autreDonation);
      }

      // IFI
      if (currentPathname.includes(lienIFI)) {
        const ifi = JSON.parse(JSON.stringify(ifiState));
        ifi.form1.configuration.client = currentData.clientId;
        ifi.form1.configuration.idBilan = null;
        ifi.form1.configuration.idFiscalite = null;
        ifi.form1.configuration.bilan_patrimonial = null;
        ifi.form1.configuration.fiscalite = null;
        ifi.form1.configuration.date_importation_bilan_patrimonial = "";
        ifi.form1.configuration.date_importation_fiscalite = "";
        ifi.form1.configuration.import_bilan_patrimonial = 0;
        ifi.form1.configuration.import_fiscalite_revenus = 0;

        setIfiState(ifi);
      }

      simulateurDispatch(updateSimulation({ ...currentData }));
    }
  }

  // Quand le formulaire CLIENT change => Modification au niveau des simulateurs selon le lien de ces derniers.
  const handleChangeClient = (identifiantClient, client) => {
    // SUCCESSION
    if (currentPathname.includes(lienSuccession)) {
      const succ = JSON.parse(JSON.stringify(successionState));
      succ.config.id_client = identifiantClient;

      setSuccessionState(succ);
    }

    // BILAN PATRIMONIAL
    /*if (currentPathname.includes(lienBilanPatrimonial)) {
      const bilan = JSON.parse(JSON.stringify(layoutState.bilanPatrimonial));

      // bilan.patrimoineGlobal.situationFamiliale = identifiantClient === 'Choisir' ? 1 : client.situation_familiale == 0 ? 2 : client.situation_familiale == 1 ? 0 : 1;

      setLayoutState((prevState) => {
        return {
          ...prevState,
          bilanPatrimonial: bilan
        }
      })
    }*/

    // IFI
    if (currentPathname.includes(lienIFI)) {
      const ifi = JSON.parse(JSON.stringify(ifiState));
      ifi.form1.configuration.client = parseInt(identifiantClient);
      ifi.form1.configuration.idBilan = null;
      ifi.form1.configuration.idFiscalite = null;
      ifi.form1.configuration.bilan_patrimonial = null;
      ifi.form1.configuration.fiscalite = null;
      ifi.form1.configuration.date_importation_bilan_patrimonial = "";
      ifi.form1.configuration.date_importation_fiscalite = "";
      ifi.form1.configuration.import_bilan_patrimonial = 0;
      ifi.form1.configuration.import_fiscalite_revenus = 0;

      setIfiState(ifi);
    }

    // DONS FAMILIAUX EXONERES
    if (currentPathname.includes(lienDonsFamiliauxExoneres)) {
      const don = JSON.parse(JSON.stringify(donsFamiliauxExoneresState));

      don.config.versionFicheClient = null;
      don.config.donateur = null;
      don.config.lienParente = 0;
      don.config.donataire = null;

      setDonsFamiliauxExoneresState(don);
    }

    // DONATION DUTREIL
    if (currentPathname.includes(lienDonationsEntreprise)) {
      const donationEntreprise = JSON.parse(JSON.stringify(donationDutreilState));

      donationEntreprise.avec_import.versionFicheClient = null
      donationEntreprise.avec_import.donateur = 0;
      donationEntreprise.avec_import.lienParente = 0;
      donationEntreprise.avec_import.donataires = [];

      setDonationDutreilState(donationEntreprise);
    }

    // AUTRES DONATIONS
    if (currentPathname.includes(lienAutresDonations)) {
      const autreDonation = JSON.parse(JSON.stringify(autreDonationState));

      autreDonation.avec_import.versionFicheClient = null
      autreDonation.avec_import.donateur = 0;
      autreDonation.avec_import.lienParente = 0;
      autreDonation.avec_import.donataires = [];

      setAutreDonationState(autreDonation);
    }

    // Modification dans Personnalisation d'enregistrement
    simulateurDispatch(updateSimulation({
      clientId: !["0", 0, "null", null, "undefined", undefined, "-1", -1].includes(identifiantClient) ? identifiantClient : null,
      idClient: ![null, 'null'].includes(identifiantClient) ? parseInt(identifiantClient) : null,
      nomClient: `${client?.nom || ""} ${client?.prenom || ""}`,
      versionOriginale: {
        ...client,
        intituleVersion: client?.intituleVersion || "version1",
        position: 1
      },
      versions: client.versions ? JSON.parse(client.versions) : []
    }))
  }

  // à chaque changement de showFormulairePersonnalisationSimulation
  useEffect(() => {
    simulateurDispatch(updateShowPersonnalisationSimulationState({ showFormulairePersonnalisationSimulation }))
  }, [showFormulairePersonnalisationSimulation])

  // à chaque fois que le lien change => On ferme la fenêtre de personnalisation de SIMULATION uniquement si elle est ouverte
  useEffect(() => {
    if (showFormulairePersonnalisationSimulation) {
      simulateurDispatch(updateShowPersonnalisationSimulationState({ showFormulairePersonnalisationSimulation: false }))
    }

    if (headerMenuIndex.ficheClient === 1 && !currentLink.includes(lienFicheClient)) {
      const headerMenu = JSON.parse(JSON.stringify(headerMenuIndex));

      headerMenu.ficheClient = 0;

      setHeaderMenuIndex(headerMenu);
    }
  }, [currentPathname])

  const onRequestFormBlur = (event) => {
    console.log("On update Bilan pat date");
    console.log(event.target.value);
    setHeaderMenuIndex((prevState) => {
      return {
        ...prevState,
        empruntSituationDu: event.target.value
      }
    })
  }

  return (
    <>
      {loading && <Loading />}
      <ModalConfirmation
        visible={visibleConfirmationMessage}
        setVisible={setVisibleConfirmationMessage}
        text="Confirmer le changement de client (et annuler l’importation des données)"
        // color='red'
        buttonValidationText="OUI"
        onOKClick={confirmerChangementClient}
      />
      <CHeader withSubheader innerRef={header} className={`entete-${typeContenu}`}>
        <CHeaderNav className="d-md-down-none mr-auto">
          <CHeaderNavItem className="">
            <CHeaderNavLink to="/simulations" style={{ color: "#1D51A1", fontWeight: 'bold' }} >SIMULATIONS</CHeaderNavLink>
          </CHeaderNavItem>
          <CHeaderNavItem className="">
            <CHeaderNavLink to="/clients" style={{ color: "#1D51A1", fontWeight: 'bold' }} >CLIENTS</CHeaderNavLink>
          </CHeaderNavItem>
          <CHeaderNavItem className="">
            <CHeaderNavLink to={lienFicheThematique} style={{ color: "#1D51A1", fontWeight: 'bold' }} >FICHES THÉMATIQUES</CHeaderNavLink>
          </CHeaderNavItem>
        </CHeaderNav>

        <CHeaderNav className="pl-3">
          <OrdinaryTooltip placement='bottom' arrow title={(localStorage.getItem('user') && JSON.parse(localStorage.getItem('user'))?.pole.abbove) ? `Charger un fichier` : "Découvrir abbove"}>
            {/** button abbove import */}
            <div>
              <Abbove showInfo={showInfo} setShowInfo={setShowInfo}  />
            </div>
          </OrdinaryTooltip>

        </CHeaderNav>

        <CHeaderNav className="pl-3">
          <OrdinaryTooltip placement='bottom' arrow title="Envoyer un mail au support support@simulabox.fr">
            <div>
              <CButton onClick={() => {
                window.location.href = "mailto:support@simulabox.fr"
              }}>
                {/* <Mailto email="support@simulabox.fr" subject="" body=""> */}
                <CImg src={SupportClientIcon} style={{ width: 40, objectFit: 'cover' }} />
                {/* </Mailto> */}
              </CButton>
            </div>
          </OrdinaryTooltip>
        </CHeaderNav>
        <CHeaderNav className="px-3">
          <div id='user-popup' onClick={togglePopup} style={{ display: 'flex', alignItems: 'center', paddingLeft: 10, paddingRight: 10, cursor: "pointer", fontSize: 13, display: "flex" }}>
            <CImg src={UtilisateurIcon} className="mr-2" style={{ width: 40, objectFit: 'cover' }} />
            <span style={{ width: '210px' }}>{user?.prenom || ""} {user?.nom || ""}</span>
            <svg style={style} xmlns="http://www.w3.org/2000/svg" width={7.5} viewBox="0 0 9.721 17.004">
              <path id="Icon_ionic-ios-arrow-up" data-name="Icon ionic-ios-arrow-up" d="M8.5,6.791,14.933.357a1.215,1.215,0,0,1,1.716,1.721L9.365,9.367A1.213,1.213,0,0,1,7.689,9.4L.354,2.083A1.215,1.215,0,0,1,2.07.362Z" transform="translate(9.721) rotate(90)" fill='#1D51A1' />
            </svg>
          </div>

          {/** Personnalisation des informations de simulation */}
          {
            show_form &&
            !conditionAffichageInfoClientExistant &&
            showFormulairePersonnalisationSimulation && (
              <div className='personnalisation-simulation' id="show-formulaire-personnalisation-simulation-content">

                {/** Client */}
                <CFormGroup className='form-inline col-lg-12 p-0 mb-3'>
                  <InputSelect
                    hidePin={true}
                    defaultValue={idCli}
                    className=''
                    custom
                    style={{ border: '1px solid #D7E2EB', width: '100%' }}
                    onChange={event => {
                      const client = clients?.find((element) => element.id == event.target.value) || {};

                      const succ = JSON.parse(JSON.stringify(successionState));
                      const don = JSON.parse(JSON.stringify(donsFamiliauxExoneresState));
                      const donationDutreil = JSON.parse(JSON.stringify(donationDutreilState));
                      const autreDonation = JSON.parse(JSON.stringify(autreDonationState));
                      const ifi = JSON.parse(JSON.stringify(ifiState));

                      // Condition d'import de données sur la SUCCESSION
                      const conditionSuccession = simulateurState.simulation?.clientId && succ.config.id_client && succ.config.client;

                      // Condition d'import de données sur les Dons familiaux exonérés
                      const conditionDFE = simulateurState.simulation?.clientId && don.config?.client && don.config?.avecImport === 1;

                      // Condition d'import de données sur les Donations Dutreil
                      const conditionDutreil = simulateurState.simulation?.clientId && donationDutreil.avec_import?.client && donationDutreil.avec_import?.avecImport === 1;

                      // Condition d'import de données sur les autres donations
                      const conditionAutreDonation = simulateurState.simulation?.clientId && autreDonation.avec_import?.client && autreDonation.avec_import?.avecImport === 1;

                      // Condition d'import de données sur l'IFI
                      const conditionAvecImportBP = ifi.form1.configuration.idBilan && ifi.form1.configuration.bilan_patrimonial && ifi.form1.configuration.import_bilan_patrimonial === 1;
                      const conditionAvecImportFiscalite = ifi.form1.configuration.idFiscalite && ifi.form1.configuration.fiscalite && ifi.form1.configuration.import_fiscalite_revenus === 1

                      const conditionIFI = simulateurState.simulation?.clientId && ifi.form1.configuration.client && (conditionAvecImportBP || conditionAvecImportFiscalite)

                      const condition = currentPathname.includes(lienSuccession) ? conditionSuccession : currentPathname.includes(lienDonsFamiliauxExoneres) ? conditionDFE : currentPathname.includes(lienDonationsEntreprise) ? conditionDutreil : currentPathname.includes(lienIFI) ? conditionIFI : conditionAutreDonation;

                      if (condition) {
                        setCurrentData({
                          client,
                          clientId: parseInt(event.target.value) || null,
                          nomClient: `${client?.prenom || ""} ${client?.nom || ""}`,
                          versionOriginale: {
                            ...client,
                            intituleVersion: client?.intituleVersion || "version1",
                            position: 1
                          },
                          versions: client.versions ? JSON.parse(client.versions) : []
                        })
                        setVisibleConfirmationMessage(!visibleConfirmationMessage);
                      }
                      else {
                        // Changement global du Client
                        simulateurDispatch(updateSimulation({ clientId: parseInt(event.target.value) || null }));

                        // Réinitialisation des versions à null
                        // Dons familiaux exonérés
                        if (currentPathname.includes(lienDonsFamiliauxExoneres)) {
                          const don = JSON.parse(JSON.stringify(donsFamiliauxExoneresState));

                          don.config.versionFicheClient = null;

                          setDonsFamiliauxExoneresState(don);
                        }

                        // Donations DUTREIL
                        if (currentPathname.includes(lienDonationsEntreprise)) {
                          const donation = JSON.parse(JSON.stringify(donationDutreilState));

                          donation.avec_import.versionFicheClient = null;

                          setDonationDutreilState(donation);
                        }

                        // Autres donations
                        if (currentPathname.includes(lienAutresDonations)) {
                          const donation = JSON.parse(JSON.stringify(autreDonationState));

                          donation.avec_import.versionFicheClient = null;

                          setAutreDonationState(donation);
                        }

                        // SUCCESSION
                        if (currentPathname.includes(lienSuccession)) {
                          const succ = JSON.parse(JSON.stringify(successionState));

                          succ.config.version_fiche_client = null;

                          setSuccessionState(succ);
                        }

                        // Bilan patrimonial
                        if (currentPathname.includes(lienBilanPatrimonial)) {
                          const bp = JSON.parse(JSON.stringify(layoutState.bilanPatrimonial));

                          bp.patrimoineGlobal.versionFicheClient = null;

                          setLayoutState((prevState) => {
                            return {
                              ...prevState,
                              bilanPatrimonial: bp
                            }
                          })
                        }

                        // IFI
                        if (currentPathname.includes(lienIFI)) {
                          const ifiData = JSON.parse(JSON.stringify(ifiState));

                          ifiData.form1.configuration.idBilan = null;
                          ifiData.form1.configuration.idFiscalite = null;

                          setIfiState(ifiData);
                        }
                      }
                    }}
                  >
                    <option value={null}>Choisir le Client</option>
                    {
                      clients.map((client, i) => (
                        <option key={`Client${client.id}`} selected={Number(client.id) === Number(idCli)} value={client.id}>{client.nom} {client.prenom}</option>
                      ))
                    }
                  </InputSelect>
                </CFormGroup>

                {/** Nom de simulation */}
                <CFormGroup className='form-inline col-lg-12 p-0 mb-3'>
                  <InputText
                    hidePin={true}
                    className='col-lg-12'
                    placeholder="Nom de la simulation..."
                    value={![null, undefined, "brouillon", ""].includes(simulateurState?.simulation?.identifiant_simulation) ? simulateurState.simulation.identifiant_simulation : ""}
                    onChange={event => { simulateurDispatch(updateSimulation({ identifiant_simulation: event.target.value })) }}
                    style={{ textAlign: 'left' }}
                  />
                </CFormGroup>

                {/** Commentaire */}
                <CFormGroup className='form-inline col-lg-12 p-0 m-0'>
                  <CTextarea
                    placeholder='Commentaire...'
                    className='col-lg-12'
                    value={simulateurState?.simulation?.commentaire || ""}
                    onChange={event => { simulateurDispatch(updateSimulation({ commentaire: event.target.value })) }}
                    style={{ height: '120px' }}
                  >
                  </CTextarea>
                </CFormGroup>
              </div>
            )
          }

          {/** Le profil utilisateur */}
          {
            isOpen && (
              <div className="popup-box" id='user-popup-content'>
                <div className="box">
                  {/*<div style={{ display: "flex", flexDirection: "column", marginBottom: 25 }}>
                    <div style={{ width: '100%', display: "flex", justifyContent: "center" }}>
                      <CImg className="animated fadeInDown" src={profil} style={{ display: "flex", height: 35, objectFit: 'cover', width: 70, height: 70 }} />
                    </div>
                    <span className="animated fadeInDown" style={{ display: "flex", flexDirection: "column", color: "#3D4760", textAlign: "center", marginTop: 8, fontWeight: "700", fontSize: 13 }}>{user && user.prenom} {user && user.nom}</span>
                  </div>*/}
                  <div className='form_profil col-lg-12'>
                    <div style={{ fontWeight: 'bold', fontSize: 14, marginBottom: 6 }}>Contact</div>

                    {/** Société */}
                    <CInput
                      placeholder='Société'
                      style={{ ...formulaireInfoUtilisateur }}
                      value={societe}
                      onChange={(event) => handleFormChange(event, 'societe')}
                    />

                    {/** Nom */}
                    <CInput
                      placeholder="Nom"
                      style={{ ...formulaireInfoUtilisateur }}
                      value={nom}
                      onChange={(event) => handleFormChange(event, 'nom')}
                    />

                    {/** Fonction */}
                    <CInput
                      placeholder="Fonction"
                      style={{ ...formulaireInfoUtilisateur }}
                      value={fonction}
                      onChange={(event) => handleFormChange(event, 'fonction')}
                    />

                    {/** Téléphone */}
                    <CInput
                      placeholder="Téléphone"
                      style={{ ...formulaireInfoUtilisateur }}
                      value={telephone}
                      onChange={(event) => handleFormChange(event, 'telephone')}
                    />

                    {/** Email */}
                    <CInput
                      placeholder="Email"
                      style={{ ...formulaireInfoUtilisateur }}
                      value={email}
                      onChange={(event) => handleFormChange(event, 'email')}
                    />

                    {/** Adresse" */}
                    <CTextarea
                      placeholder="Adresse"
                      style={{ ...formulaireInfoUtilisateur, height: '70px' }}
                      value={adresse}
                      onChange={(event) => handleFormChange(event, 'adresse')}
                    />

                    <div style={{ height: '27px', fontWeight: 'bold', fontSize: 14, marginBottom: 5, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                      <div>Mentions légales</div>
                      <div style={{ marginTop: 6 }}>
                        <Switch
                          checked={avecMentionLegale === true}
                          onChange={(event) => handleFormChange(event, 'avecMentionLegale')}
                        />
                      </div>
                    </div>

                    {/** Mentions légales */}
                    <CTextarea
                      style={{ ...formulaireInfoUtilisateur, height: '200px', marginBottom: 0 }}
                      value={mentionLegale}
                      onChange={(event) => handleFormChange(event, 'mentionLegale')}
                    />

                    <div style={{ display: "flex", justifyContent: "center", marginTop: 20, position: 'relative', width: '100%' }}>
                      {/** Bouton d'enregistrement */}
                      <div style={{ position: 'absolute', left: 0 }}>
                        <OrdinaryTooltip title="Enregistrer" arrow={true} placement='bottom'>
                          <div onClick={addInfoUtilisateur} style={{ cursor: 'pointer' }}>
                            <CImg src={BoutonValidationPersonnalisationInformationSimulation} width={25} />
                          </div>
                        </OrdinaryTooltip>
                      </div>

                      {/** Bouton de fermeture */}
                      <div>
                        <OrdinaryTooltip title="Fermer" arrow={true} placement='bottom'>
                          <div onClick={() => { setIsOpen(!isOpen) }} style={{ cursor: 'pointer' }}>
                            <CImg src={BoutonFermeturePersonnalisationInformationSimulation} width={20} />
                          </div>
                        </OrdinaryTooltip>
                      </div>

                      {/** Bouton de déconnexion */}
                      <div style={{ position: 'absolute', right: 0 }}>
                        <OrdinaryTooltip title="Se déconnecter" arrow={true} placement='bottom'>
                          <div onClick={deconnexion} style={{ cursor: 'pointer' }}>
                            <CImg src={BoutonDeconnexion} width={20} />
                          </div>
                        </OrdinaryTooltip>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )
          }

        </CHeaderNav>
        {
          !noSubheader && (
            <CSubheader className="px-3 d-flex" style={{ justifyContent: 'flex-start' }}>
              {
                currentPathname.includes(`${lienFicheThematique}/`) && (
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      position: 'absolute',
                      left: 0,
                      top: 0,
                      width: '20px',
                      height: '60px',
                      background: '#EF95AA',
                      cursor: 'pointer'
                    }}
                    onClick={() => {
                      setHeaderMenuIndex((prevState) => {
                        return {
                          ...prevState,
                          succession: 0
                        }
                      })
                      history.goBack()
                    }}
                  >
                    <CImg src={ArrowBackFT} style={{ width: 10 }} />
                  </div>
                )
              }
              <div style={{ width: avecFormulaireExceptionnel ? '30%' : '90%', paddingLeft: '11px' }}>
                {/** Le simulateur actif en question */}
                <div style={{ fontSize: '16px', color: '#686E7C', fontWeight: 'bold', marginBottom: 5 }}>{simulateurActif}</div>

                {/** La file d'Ariane */}
                <div className='bloc-fil-ariane'>
                  <CBreadcrumbRouter className="border-0 c-subheader-nav m-0 px-0" routes={routes2} />
                </div>
              </div>

              {/** Les formulaires exceptionnels et bouton de personnalisation de simulation */}
              <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', width: avecFormulaireExceptionnel ? '70%' : '10%' }}>
                {
                  avecFormulaireExceptionnel && (
                    <div style={{ marginRight: 40 }}>
                      {
                        // Pour le BILAN PATRIMONIAL
                        currentPathname === lienBilanPatrimonial ? (
                          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            {/** Type d'affichage */}
                            <div style={{ marginRight: 40 }}>
                              <CFormGroup className='form-inline m-0' style={{ flexWrap: 'nowrap' }}>
                                <CLabel style={{ fontSize: 13, marginRight: 20 }}>Affichage</CLabel>
                                <InputSelect
                                  hidePin={true}
                                  custom
                                  style={{
                                    border: [1, 2, 3].includes(bilanPatrimonial.patrimoineGlobal.situationFamiliale) &&
                                    [2, 3].includes(bilanPatrimonial.affichage) &&
                                    headerMenuIndex.bilanPatrimonial !== 0 ? String(bilanPatrimonial.affichage) === "2" ? "2px solid #318FCC" : "2px solid #F47F4F" : 'inherit', borderRadius: '0.25rem',
                                  }}
                                  disabled={headerMenuIndex.bilanPatrimonial !== 0 || [0, 4, 5].includes(bilanPatrimonial.patrimoineGlobal.situationFamiliale)}
                                  onChange={(event) => handleInformationsBilanPatrimonialChange(event, 'affichage')}
                                  defaultValue={bilanPatrimonial.affichage}
                                  className='A'
                                >
                                  {
                                    situationFamilialeData.map((data, index) => {
                                      return (
                                        <option key={index} selected={bilanPatrimonial.affichage === data.level} value={data.level}>{data.label}</option>
                                      )
                                    })
                                  }
                                </InputSelect>
                              </CFormGroup>
                            </div>

                            {/** Date du bilan patrimonial */}
                            <div>
                              <CFormGroup className='form-inline m-0' style={{ flexWrap: 'nowrap' }}>
                                <CLabel style={{ fontSize: 13, marginRight: 20, whiteSpace: 'nowrap' }}>Situation au</CLabel>
                                <InputDate
                                  id={`${formatToUniqueId("TheHeader Situation au")}-date`}
                                  hidePin={true}
                                  disabled={headerMenuIndex.bilanPatrimonial !== 0}
                                  value={bilanPatrimonial.situationDu}
                                  invalid={headerMenuIndex.bilanPatrimonial === 0 && !isCompleteDate(bilanPatrimonial.situationDu)}
                                  onChange={(event) => handleInformationsBilanPatrimonialChange(event, 'situationDu')}
                                  onBlur={onRequestFormBlur}
                                  className='A'
                                  style={{ width: '150px' }}
                                />
                              </CFormGroup>
                            </div>
                          </div>
                        ) :
                          // Pour la Fiscalité des revenus
                          currentPathname === lienFiscaliteRevenus ? (
                            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                              {/** Fiscalité annuelle */}
                              <div style={{ marginRight: 40 }}>
                                <CFormGroup className='form-inline m-0' style={{ position: 'relative', flexWrap: 'nowrap' }}>
                                  <CLabel style={{ fontSize: 13, marginRight: 20, height: '35px' }}>
                                    Fiscalité annuelle
                                  </CLabel>
                                  <InputCurrency
                                    hidePin={true}
                                    id={`${formatToUniqueId("Fiscalité annuelle provisoire")}`}
                                    className="A"
                                    disabled={true}
                                    value={Math.round(autreState?.patrimoineFiscalite?.fiscaliteRevenus?.fiscaliteAnnuelle || 0)}
                                    style={{ /*background: '#FFE5EA',*/ fontSize: 13, width: '130px' }}
                                  />
                                </CFormGroup>
                              </div>

                              {/** Revenus de l'année */}
                              <div>
                                <CFormGroup className='form-inline m-0' style={{ flexWrap: 'nowrap' }}>
                                  <CLabel style={{ fontSize: 13, marginRight: 20 }}>Revenus de l'année</CLabel>
                                  <InputSelect
                                    hidePin={true}
                                    className="A"
                                    custom
                                    style={{ textAlign: "right"/*, background: 'rgb(216,245,255)'*/, width: '80px' }}
                                    defaultValue={fiscaliteState.annee_en_cours}
                                    onChange={(event) => {
                                      const fisc = JSON.parse(JSON.stringify(fiscaliteState));
                                      const annee = parseInt(event.target.value, 10);

                                      if (annee !== 2023 && fisc.calcul1.calculette1.revenus_et_charges_annee.limite_importation_rev_global === 21400) {
                                        fisc.calcul1.calculette1.revenus_et_charges_annee.limite_importation_rev_global = 10700;
                                        fisc.calcul1.calculette1.revenus_et_charges_annee.dont_travaux_deductible_renovation_energetique = 0;
                                      }

                                      fisc.withCalcul = true;
                                      fisc.annee_en_cours = `${annee}-12-12`;
                                      fisc.foyer_fiscal.annee_revenu = annee;
                                      fisc.foyer_fiscal.annee = annee;

                                      setFiscaliteState(fisc);
                                    }}
                                  >
                                    {
                                      anneesFiscales.map((data, index) => {
                                        return (
                                          <option value={data.label} selected={data.label === fiscaliteState.annee_en_cours}>{data.label}</option>
                                        )
                                      })
                                    }
                                  </InputSelect>
                                  <InfoBulle
                                    style={{ visibility: 'hidden' }}
                                    textAlign='left'
                                    information={
                                      <div>
                                        Certaines dispositions fiscales issues des Lois de finances de la fin d’année 2022 s’appliquent à compter de l’imposition des revenus de 2023.<br />
                                        Cela concerne la limite de déduction du déficit foncier imputable sur le revenu global. Cette limite est relevée de 10 700 € à 21 400 € pour certains travaux de rénovation énergétiques payés entre 2023 et 2025.
                                        Cela concerne aussi les seuils des régimes micro-BIC, BNC et BA qui sont relevés pour les années 2023 à 2025.
                                      </div>
                                    }
                                  />
                                </CFormGroup>
                              </div>
                            </div>
                          ) :
                            // Pour l'IFI
                            (
                              <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
                                <CFormGroup className='form-inline m-0'>
                                  <CLabel style={{ fontSize: 13, marginRight: 20 }}>Année fiscale</CLabel>
                                  <InputSelect
                                    hidePin={true}
                                    className='A'
                                    custom
                                    style={{ width: '100px'/*, background: '#D8F5FF'*/, border: ifiState.affichageMessage === 1 && '1px solid red' }}
                                    // value={ifiState.anneeFiscale}
                                    onChange={(event) => {
                                      const ifi = JSON.parse(JSON.stringify(ifiState));
                                      ifi.anneeFiscale = parseInt(event.target.value);
                                      ifi.withCalcul = true;

                                      setIfiState(ifi);
                                    }}
                                  >
                                    {
                                      anneesFiscalesIFI.map((data, index) => {
                                        return (
                                          <option key={`AnneeFiscaleIFI${index + 1}`} selected={ifiState.anneeFiscale === data.value} value={data.value}>{data.label}</option>
                                        )
                                      })
                                    }
                                  </InputSelect>
                                </CFormGroup>
                              </div>
                            )
                      }
                    </div>
                  )
                }

                {/** Bouton de personnalisation de SIMULATION */}
                {
                  show_form && (
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>

                      {/** Boutons de raccourci */}
                      <Shortcuts />
                      <div
                        onClick={() => {
                          if (!conditionAffichageInfoClientExistant) {
                            simulateurDispatch(updateShowPersonnalisationSimulationState({
                              showFormulairePersonnalisationSimulation: !showFormulairePersonnalisationSimulation
                            }))
                          }
                        }}
                        id='show-formulaire-personnalisation-simulation'
                        className='personnalisation-enregistrement'
                        style={{ display: 'flex', alignItems: 'center', padding: 10, cursor: !conditionAffichageInfoClientExistant ? 'pointer' : undefined }}
                      >
                        <CImg src={Gadget} className="mr-2" style={{ width: 40, objectFit: 'cover' }} />
                        <div
                          className='texte-personnalisation-enregistrement'
                          style={{
                          }}>
                          {/** Personnalisation de l'enregistrement */}
                          {
                            !conditionAffichageInfoClientExistant ? (
                              <>
                                {
                                  ![null, 'null', undefined].includes(simulateurState.simulation?.clientId) ? `${clients?.find((element) => element.id === Number(simulateurState.simulation.clientId))?.prenom || ""} ${clients?.find((element) => element.id === Number(simulateurState.simulation.clientId))?.nom || ""}` : <>Personnaliser <br /> l'enregistrement</>
                                }
                              </>
                            ) : (
                              <>{ficheClientState?.conjoints?.[0]?.nom?.toUpperCase() || ""} {ficheClientState?.conjoints?.[0]?.prenom || ""}</>
                            )
                          }
                        </div>
                        <svg style={{ visibility: conditionAffichageInfoClientExistant ? 'hidden' : 'visible', transform: showFormulairePersonnalisationSimulation ? "rotate(-90deg)" : "", transition: 'transform 150ms ease', marginLeft: 12 }} xmlns="http://www.w3.org/2000/svg" width={7.5} viewBox="0 0 9.721 17.004">
                          <path id="Icon_ionic-ios-arrow-up" data-name="Icon ionic-ios-arrow-up" d="M8.5,6.791,14.933.357a1.215,1.215,0,0,1,1.716,1.721L9.365,9.367A1.213,1.213,0,0,1,7.689,9.4L.354,2.083A1.215,1.215,0,0,1,2.07.362Z" transform="translate(9.721) rotate(90)" fill='rgb(142,95,168)' />
                        </svg>
                      </div>
                    </div>
                  )
                }
              </div>
            </CSubheader>
          )
        }
      </CHeader>
      {
        (is_result_pea || is_result_ct || is_result_av || is_result_capi || is_result_is) &&
        <Hypothese category={is_result_pea ? 'PEA' : is_result_ct ? 'CT' : is_result_av ? 'AV' : is_result_capi ? 'CAPI' : 'IS'} />
      }

      {/** Cas exceptionnel de la Comparaison des structures */}
      {
        is_page_cmp && (
          <div className={menuClassName} ref={menu_onglet} style={{ padding: '11px', paddingBottom: 0, background: COULEUR_FOND_SIMULATEUR, position: 'sticky', top: '115px', zIndex: 100 }}>
            <div id="menuFelcheBleu" className={` ${[true, 'responsive'].includes(utilsState.sidebarShow) ? 'sidebar-show' : ''} d-flex flex-direction-row align-items-center justify-content-center`}>
              <div
                className={`pointer ${cmpStep === 1 ? 'active' : ''}`}
                onClick={() => simulateurDispatch({ type: 'COMP_SSI' })}
              >
                Sélection des structures d'investissement à comparer
              </div>
              <div
                className={`pointer ${cmpStep === 2 ? 'active' : ''}`}
                onClick={() => simulateurDispatch({ type: 'COMP_HSF' })}
              >
                Hypothèses des simulations financières
              </div>
              <div
                className={`pointer ${cmpStep === 3 ? 'active' : ''}`}
                onClick={() => simulateurDispatch({ type: 'COMP_RSF' })}
              >
                Résultats des simulations financières
              </div>
              <div
                className={`pointer ${cmpStep === 4 ? 'active' : ''}`}
                onClick={() => simulateurDispatch({ type: 'COMP_HST' })}
              >
                Hypothèses des simulations de transmission
              </div>
              <div
                className={`pointer ${cmpStep === 5 ? 'active' : ''}`}
                onClick={() => simulateurDispatch({ type: 'COMP_RST' })}
              >
                Résultats des simulations de transmission
              </div>
            </div>
          </div>
        )
      }

      <ModalRightSidebar hideOverlay={true} width={"90%"} title={`Abbove`} isOpen={showInfo} closeSidebar={() => setShowInfo(false)}>
          <AbboveDecouverte />
      </ModalRightSidebar>
    </>
  )
}

export default TheHeader
