import { restApiUri } from "../../config";
import { now } from "../../extra/all_ppt_render";
import { lienAutresDonations, lienBilanPatrimonial, lienDonationsEntreprise, lienDonsFamiliauxExoneres, lienEmprunt, lienFicheClient, lienFicheThematiqueAssuranceVie, lienFicheThematiqueCapi, lienFicheThematiqueCessionSociete, lienFicheThematiqueClauseBeneficiaireAV, lienFicheThematiqueCompteTitres, lienFicheThematiqueDMTG, lienFicheThematiqueIFI, lienFicheThematiquePEA, lienFicheThematiqueRevLocatif, lienFicheThematiqueSocieteIS, lienFiscaliteRevenus, lienIFI, lienPlusValueImmobiliere, lienSimulateurCessionSociete, lienSuccession, lienUsufruitEconomique, lienUsufruitTemporaire, lienUsufruitViager } from "../../routes";
import { completeDate } from "../../utils";
import { cloneBilan, cloneIfi, getIfi, saveIfi } from "../BilanPatrimonial/BilanDataControl";
import { cloneFiscalite } from "../BilanPatrimonial/FiscaliteRevenu/FiscaliteDataControl";
import { structurationData } from "../Cession/CessionHaut/CessionHaut";
import { structurationDataSimulation } from "../Cession/CessionHaut/ResultatSimulation/ResultatSimulation";
import { cloneSuccession } from "../DonationSuccession/Succession/SuccessionDataControl";
import { currencyToSend } from "../DonationSuccession/UsufruitNP/UsufruitViager/UsufruitViager";
import { cloneEmprunt, cloneUsuf, getEmprunt, getUsufEco, saveEmprunt, saveUsufEco } from "../InvestissementsImmobiliers/ImmoDataControl";
import { getSimulateur } from "../InvestissementsImmobiliers/PlusValueImmobiliere/Crud";
import { savePvImmobilier } from "../InvestissementsImmobiliers/PlusValueImmobiliere/PlusValueImmobiliere";

const idUser = JSON.parse(localStorage.getItem('user'))?.id || null;

const prefixeSimulationIssueDeDuplication = (nomSimulation) => {
  return `Copie - ${![null, undefined, ""].includes(nomSimulation) ? nomSimulation : 'brouillon'}`;
}

export const setZeroValue = (obj) => {
  let res = {}
  Object.keys(obj).forEach(k => {
    res[k] = obj[k] || 0
  });
  return res
}

export const initSelectedSimulators = (type) => {
  let data = {}
  //let situation_familiale = 1
  if (type === 0 || type === 1 || type === 2) {
    data = {
      type,
      pourcentage_investi_eu: 0,
      droit_contrat: 0,
      tx_redement_annuel_brut_eu: 0,
      tx_redement_annuel_brut_uc: 0,
      frais_gestion_administrative_eu: 0,
      frais_gestion_administrative_uc: 0,
      frais_gestion_financier_eu: 0,
      frais_gestion_financier_uc: 0,
      assiette_frais_uc: 100,
      montant_investi: this.state.montant_investi
    }
    //situation_familiale = 2
  }

  if (type === 3) {
    data = {
      type: 3,
      frais_annuel: 0,
      rendement: 0,
      tx_annuel_brut: 0,
      montant_investi: this.state.montant_investi
    }
  }
  else if (type === 4) {
    data = {
      type: 4,
      type_pea: "PEA CLASSIQUE",
      tx_annuel_brut: 0,
      frais_annuel: 0,
      montant_investi: this.state.montant_investi
    }
  }
  else if (type === 5) {
    data = {
      type: 5,
      frais_annuel: 0,
      tx_annuel_brut: 0,
      montant_resultat_reserve: 0,
      montant_compte_courant: 0,
      montant_investi: this.state.montant_investi
    }
  }
  //this.setState({situation_familiale})
  return data
}


// Clonage Proposition financière
export const pfClone = (id) => {

  return new Promise((resolve, reject) => {

    fetch(`${restApiUri}/api/financiere/findFinanciere/` + id, {
      method: "GET",
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${localStorage.getItem("token")}`
      }
    })
      .then(resp => resp.json())
      .then(res => {

        if (res.error) {
          reject(res?.error)
        } else {

          const alternativeData = JSON.parse(JSON.stringify(res));
          let data = res.data
          let afficher = {}
          for (const [key, value] of Object.entries(res.afficher)) {
            afficher[`${key}`] = { montant_global: value.montant_global }
            if (`table_${key.toLowerCase()}` in value)
              afficher[`${key}`][`liste_${key.toLowerCase()}`] = value[`table_${key.toLowerCase()}`].map(obj => { return { ...obj, objectif1: obj.objectif1.texte_libre ? obj.objectif1.texte_libre : obj.objectif1.type, objectif2: obj.objectif2.texte_libre ? obj.objectif2.texte_libre : obj.objectif2.type, objectif3: obj.objectif3.texte_libre ? obj.objectif3.texte_libre : obj.objectif3.type } })
          }
          data = { ...data, ...afficher }
          delete data['id']
          delete data['id']
          delete data['createdAt']
          delete data['client']
          delete data['compte_titre']
          delete data['pea_pme']
          delete data['assurance_vie']
          delete data['pea']
          delete data['capitalisation']

          const newPropo = JSON.parse(JSON.stringify(data));
          const peaPme = alternativeData?.afficher?.PEA_PME?.tab_pea_pme?.[0] || {};

          newPropo.PEA_PME.liste_pea_pme = [];
          if (peaPme.montant_investi > 0) {
            newPropo.PEA_PME.liste_pea_pme.push({
              montant_investi: peaPme.montant_investi,
              orientation_de_gestion: peaPme.orientation_de_gestion || "",
              objectif1: peaPme.objectif1?.type || "",
              objectif2: peaPme.objectif2?.type || "",
              objectif3: peaPme.objectif3?.type || "",
              banque: peaPme.banque || ""
            })
          }


          fetch(`${restApiUri}/api/financiere/`, {
            method: "POST",
            headers: {
              'Content-Type': 'application/json',
              Accept: 'application/json',
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
            body: JSON.stringify({
              ...newPropo,
              identifiant_simulation: prefixeSimulationIssueDeDuplication(newPropo.identifiant_simulation)
            })
          })
            .then(resp => resp.json())
            .then(data => {
              resolve(data)
            })
        }
      }).catch(e => reject(e))
  })

}

// Clonage Comparaison
export const cmpClone = (id) => {

  return new Promise((resolve, reject) => {
    fetch(`${restApiUri}/api/comparateur/findComparateur/` + id, {
      method: "GET",
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${localStorage.getItem("token")}`
      }
    })
      .then(resp => resp.json())
      .then(res => {

        if (res.error) {
          reject(res?.error)
        } else {

          let data = res.data
          delete data['id']
          data['liste_beneficiaire'] = res.struct_benef ? res.struct_benef : [{ valeur_fiscale: 0, annee: '', abattement_fiscale: 0, nom: "beneficiaire 1", handicape: 0 }]
          data['liste_structure_comparer'] = data.liste_structure_comparer ? data.liste_structure_comparer.split(',').map(type => { return parseInt(type) }) : [0]
          if (data.type_simulation === 1) {
            data['liste_simulateur_a_comparer'] = res.struct_sur_mesure ? res.struct_sur_mesure.map(simu => { return { ...simu.simulateur, type_pea: simu.simulateur.type, type: simu.id } }) : data.liste_structure_comparer.map(type => { return initSelectedSimulators(type) })
            data['liste_simulateur_sur_mesure'] = data.liste_simulateur_a_comparer.map((simulateur, i) => {
              return {
                id: simulateur.type,
                simulateur: simulateur.type === 4 ? {
                  ...setZeroValue(simulateur),
                  type: simulateur.type_pea
                } : {
                  ...setZeroValue(simulateur)
                }
              }
            })
          }
          const newCmp = JSON.parse(JSON.stringify(data));

          fetch(`${restApiUri}/api/comparateur/`, {
            method: "POST",
            headers: {
              'Content-Type': 'application/json',
              Accept: 'application/json',
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
            body: JSON.stringify({
              ...newCmp,
              identifiant_simulation: prefixeSimulationIssueDeDuplication(newCmp.identifiant_simulation),
              versement_table_complementaire: newCmp.versement_complementaire,
              listeMontantSouhaiter: newCmp.montant_souhaite
            })
          })
            .then(resp => resp.json())
            .then(data => {
              resolve(data)

            })
        }
      }).catch(e => reject(e))
  });
}

// Clonage IS
export const isClone = (id) => {

  return new Promise((resolve, reject) => {

    fetch(`${restApiUri}/api/steis/findSteis/` + id, {
      method: "GET",
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${localStorage.getItem("token")}`
      }
    })
      .then(resp => resp.json())
      .then(data => {

        if (data.error) {
          reject(data?.error)
        } else {

          delete data['id']
          const {
            identifiant_simulation,
            duree,
            annee,
            mois,
            montant_investi,
            tx_annuel_brut,
            frais_annuel,
            tx_annuel_net,
            montant_compte_courant,
            montant_resultat_reserve,
            montant_retrait,
            terme_simulation,
            clientId,
            userId,
            commentaire,
            date_simulation } = data
          fetch(`${restApiUri}/api/steis/`, {
            method: "POST",
            headers: {
              'Content-Type': 'application/json',
              Accept: 'application/json',
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
            body: JSON.stringify({
              identifiant_simulation: prefixeSimulationIssueDeDuplication(identifiant_simulation),
              duree,
              annee,
              mois,
              montant: montant_investi,
              tx_annuel_brut,
              frais_annuel,
              tx_annuel_net,
              montant_compte_courant,
              montant_resultat_reserve,
              montantRetrait: montant_retrait,
              terme_simulation: terme_simulation ? terme_simulation : 'fin',
              clientId,
              userId,
              commentaire,
              date_simulation
            })
          })
            .then(resp => resp.json())
            .then(data => {
              resolve(data)
            })
        }
      }).catch(e => reject(e))
  });

}

// Clonage AV
export const avClone = (id) => {

  return new Promise((resolve, reject) => {

    fetch(`${restApiUri}/api/assurance/findAssurance/` + id, {
      method: "GET",
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${localStorage.getItem("token")}`
      }
    })
      .then(resp => resp.json())
      .then(data => {

        if (data.error) {
          reject(data?.error)
        } else {

          delete data['id']
          const {
            identifiant_simulation,
            duree,
            annee,
            mois,
            montant_investi,
            droit_contrat,
            frais_financiere_eu,
            frais_financiere_uc,
            frais_administrative_eu,
            frais_administrative_uc,
            assiette_financier_uc,
            situation_familiale,
            support_investissement,
            source_retrait,
            pourcentage_investi_eu,
            tx_annuel_brut_eu,
            tx_annuel_brut_uc,
            terme_simulation,
            clientId,
            userId,
            commentaire,
            montant_retrait,
            type,
            date_simulation } = data
          fetch(`${restApiUri}/api/assurance/`, {
            method: "POST",
            headers: {
              'Content-Type': 'application/json',
              Accept: 'application/json',
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
            body: JSON.stringify({
              identifiant_simulation: prefixeSimulationIssueDeDuplication(identifiant_simulation),
              duree,
              annee,
              mois,
              montant_investi,
              droit_contrat,
              frais_gestion_administrative_eu: frais_administrative_eu,
              frais_gestion_administrative_uc: frais_administrative_uc,
              frais_gestion_financier_eu: frais_financiere_eu,
              frais_gestion_financier_uc: frais_financiere_uc,
              assiette_frais_uc: assiette_financier_uc,
              situation_familiale,
              support_investissement,
              source_retrait,
              pourcentage_investi_eu,
              tx_redement_annuel_brut_eu: tx_annuel_brut_eu,
              tx_redement_annuel_brut_uc: tx_annuel_brut_uc,
              terme_simulation,
              clientId,
              userId,
              commentaire,
              montant_retrait: montant_retrait,
              date_simulation,
              is_capitalisation: type === "Capitalisation" ? true : null
            })
          })
            .then(resp => resp.json())
            .then(data => {

              resolve(data)
            })
        }
      }).catch(e => reject(e))
  })

}

// Clonage CT
export const ctClone = (id) => {
  return new Promise((resolve, reject) => {

    fetch(`${restApiUri}/api/titre/findTitre/` + id, {
      method: "GET",
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${localStorage.getItem("token")}`
      }
    })
      .then(resp => resp.json())
      .then(data => {

        if (data.error) {
          reject(data?.error)
        } else {

          delete data['id']
          const { annee, clientId, userId, commentaire, duree, frais_annuel, identifiant_simulation, mois, montant_investi, montant_retrait, statut, terme_simulation, tx_annuel_brut, rendement, capitalisation, date_simulation } = data
          fetch(`${restApiUri}/api/titre/`, {
            method: "POST",
            headers: {
              'Content-Type': 'application/json',
              Accept: 'application/json',
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
            body: JSON.stringify({
              annee,
              clientId,
              userId,
              commentaire,
              duree,
              frais_annuel,
              identifiant_simulation: prefixeSimulationIssueDeDuplication(identifiant_simulation),
              mois,
              montant: montant_investi,
              montantRetrait: montant_retrait,
              statut,
              terme_simulation,
              tx_annuel_brut,
              rendement,
              capitalisation,
              date_simulation
            })
          })
            .then(resp => resp.json())
            .then(data => {

              resolve(data)
            })
        }
      }).catch(e => reject(e))
  })
}

// Clonage PEA
export const peaClone = (id) => {

  return new Promise((resolve, reject) => {

    fetch(`${restApiUri}/api/PEA/findPea/` + id, {
      method: "GET",
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${localStorage.getItem("token")}`
      }
    })
      .then(resp => resp.json())
      .then(data => {

        if (data.error) {
          reject(data?.error)
        } else {

          delete data['id']
          const { annee, clientId, userId, commentaire, duree, frais_annuel, identifiant_simulation, mois, montant_investi, montant_retraits, plafond, statut, terme_simulation, tx_annuel_brut, type, date_simulation } = data
          fetch(`${restApiUri}/api/PEA/`, {
            method: "POST",
            headers: {
              'Content-Type': 'application/json',
              Accept: 'application/json',
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
            body: JSON.stringify({
              annee,
              clientId,
              userId,
              commentaire,
              duree,
              fraisAnnuel: frais_annuel,
              identifiant_simulation: prefixeSimulationIssueDeDuplication(identifiant_simulation),
              mois,
              montant: montant_investi,
              montantRetrait: montant_retraits,
              plafond,
              statut,
              terme_simulation,
              txAnnuelBrut: tx_annuel_brut,
              type,
              date_simulation
            })
          })
            .then(resp => resp.json())
            .then(data => {

              resolve(data)
            })
        }
      }).catch(e => reject(e))
  })

}

// Clonage Cession de société
export const cessionClone = (id) => {

  return new Promise((resolve, reject) => {

    fetch(`${restApiUri}/api/cession/findCession/` + id, {
      method: "GET",
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${localStorage.getItem("token")}`
      }
    })
      .then(resp => resp.json())
      .then(data => {

        if (data.error) {
          reject(data?.error)
        } else {
          ;
          delete data['id']
          const structuredData = structurationData(data); // En state du contexte.
          // eslint-disable-next-line no-new-object
          const simulation = new Object();
          simulation["identifiant_simulation"] = structuredData.id;
          simulation["terme_simulation"] = structuredData.terme;
          simulation["commentaire"] = structuredData.commentaire;
          simulation["clientId"] = structuredData.clientId;
          simulation["userId"] = structuredData.userId;

          const cession = {
            identiteActionnaire: structuredData.identiteActionnaire,
            projetCessionSociete: structuredData.projetCessionSociete,
            operationsAvantCession: structuredData.operationsAvantCession
          }

          // eslint-disable-next-line no-new-object
          const simu = new Object();
          simu.simulation = simulation;

          const realData = structurationDataSimulation(
            cession,
            simu
          );

          fetch(`${restApiUri}/api/cession/`, {
            method: "POST",
            headers: {
              'Content-Type': 'application/json',
              Accept: 'application/json',
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
            body: JSON.stringify({
              ...realData,
              identifiant_simulation: prefixeSimulationIssueDeDuplication(realData.identifiant_simulation),
              date_simulation: data.date_simulation
            })
          })
            .then(resp => resp.json())
            .then(data => {

             resolve(data)
            })
        }
      }).catch(e => reject(e))
  })

}

// Clonage DFE
export const dfClone = (id) => {

  return new Promise((resolve, reject) => {

    fetch(`${restApiUri}/api/donationFamiliaux/findOne/${id}`, {
      method: "GET",
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${localStorage.getItem("token")}`
      }
    })
      .then(resp => resp.json())
      .then(data => {

        if (data.error) {
          reject(data?.error)
        } else {
          ;
          delete data['id']
          // eslint-disable-next-line no-new-object
          const simulation = new Object();

          // eslint-disable-next-line no-new-object
          const simu = new Object();
          simu.simulation = simulation;

          const don = JSON.parse(JSON.stringify(data));

          fetch(`${restApiUri}/api/donationFamiliaux/`, {
            method: "POST",
            headers: {
              'Content-Type': 'application/json',
              Accept: 'application/json',
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
            body: JSON.stringify({
              ...data,
              identifiant_simulation: prefixeSimulationIssueDeDuplication(don.identifiant_simulation),
              clientId: data?.client?.id || null,
              commentaire: data.commentaire,
              terme_simulation: "fin",
              userId: idUser,
              don_somme_argent: data.don_somme_argent,
              don_pp: data.don_pp,
              donateur_age_de_moins_de_80_ans: data.donateur_age_de_moins_de_80_ans,
              beneficiaire_majeur: data.beneficiaire_majeur,
              lien_de_parente_avec_le_beneficiaire: data.lien_de_parente_avec_le_beneficiaire,
              montant_des_dons_anterieurs_exoneres: data.montant_des_dons_anterieurs_exoneres,
              config: data.config
            })
          })
            .then(resp => resp.json())
            .then(data => {

              resolve(data)
            })
        }
      }).catch(e => reject(e))
  })

}

// Clonage DO
export const doClone = (id) => {

  return new Promise((resolve, reject) => {

    fetch(`${restApiUri}/api/donationOrdinaire/findOne/${id}`, {
      method: "GET",
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${localStorage.getItem("token")}`
      }
    })
      .then(resp => resp.json())
      .then(data => {

        if (data.error) {
          reject(data?.error)
        } else {
          ;
          delete data['id']

          const donation = JSON.parse(JSON.stringify(data));

          fetch(`${restApiUri}/api/donationOrdinaire/`, {
            method: "POST",
            headers: {
              'Content-Type': 'application/json',
              Accept: 'application/json',
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
            body: JSON.stringify({
              ...data,
              identifiant_simulation: prefixeSimulationIssueDeDuplication(donation.identifiant_simulation),
              clientId: data?.client?.id || null,
              commentaire: data.commentaire,
              terme_simulation: "fin",
              userId: idUser,
              lien_parenter: data.lien_parenter,
              nb_beneficiaire: parseInt(data.nb_beneficiaire, 10),
              handicape: data.handicape,
              valeur_fiscale: currencyToSend(data.valeur_fiscale),
              abattement_fiscale: currencyToSend(data.abattement_fiscale),
              annee: parseInt(data.annee, 10),
              donation_pp_dutriel: currencyToSend(data.donation_pp_dutriel),
              donation_np_dutriel: currencyToSend(data.donation_np_dutriel),
              age: parseInt(data.age, 10),
              hypothese_frais_de_notaire: parseFloat(data.hypothese_frais_de_notaire),
              avec_import: data.avec_import
            })
          })
            .then(resp => resp.json())
            .then(data => {

              resolve(data)
            })
        }
      }).catch(e => reject(e))
  })

}

// Clonage DE
export const deClone = (id) => {

  return new Promise((resolve, reject) => {

    fetch(`${restApiUri}/api/donationEntreprise/findOne/${id}`, {
      method: "GET",
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${localStorage.getItem("token")}`
      }
    })
      .then(resp => resp.json())
      .then(data => {

        if (data.error) {
          reject(data?.error)
        } else {
          ;
          delete data['id']

          const donation = JSON.parse(JSON.stringify(data));

          fetch(`${restApiUri}/api/donationEntreprise/`, {
            method: "POST",
            headers: {
              'Content-Type': 'application/json',
              Accept: 'application/json',
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
            body: JSON.stringify({
              ...data,
              identifiant_simulation: prefixeSimulationIssueDeDuplication(donation.identifiant_simulation),
              clientId: data?.client?.id || null,
              commentaire: data.commentaire,
              terme_simulation: "fin",
              userId: idUser,
              lien_parenter: data.lien_parenter,
              nb_beneficiaire: data.nb_beneficiaire,
              handicape: data.handicape,
              valeur_fiscale: data.valeur_fiscale,
              abattement_fiscale: data.abattement_fiscale,
              annee: data.annee,
              donation_pp_dutriel: data.donation_pp_dutriel,
              donation_np_dutriel: data.donation_np_dutriel,
              age: data.age,
              hypothese_frais_de_notaire: data.hypothese_frais_de_notaire,
              eligibilite_au_regime_dutreil: data.eligibilite_au_regime_dutreil,
              avec_import: data.avec_import
            })
          })
            .then(resp => resp.json())
            .then(data => {

              resolve(data)
            })
        }
      }).catch(e => reject(e))
  })

}

// Clonage USUF Viager
export const usufViagerClone = (id) => {

  return new Promise((resolve, reject) => {

    fetch(`${restApiUri}/api/usufruit/findOneUsufruitViager/${id}`, {
      method: "GET",
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${localStorage.getItem("token")}`
      }
    })
      .then(resp => resp.json())
      .then(data => {

        if (data.error) {
          reject(data?.error)
        } else {
          ;
          delete data['id']

          const usuf = JSON.parse(JSON.stringify(data));

          fetch(`${restApiUri}/api/usufruit/addUsufruitViager`, {
            method: "POST",
            headers: {
              'Content-Type': 'application/json',
              Accept: 'application/json',
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
            body: JSON.stringify({
              identifiant_simulation: prefixeSimulationIssueDeDuplication(usuf.identifiant_simulation),
              clientId: data.clientId,
              commentaire: data.commentaire,
              terme_simulation: "fin",
              userId: data.userId,
              age: data.age,
              contre_valeur: data.contre_valeur,
              date_simulation: data.date_simulation
            })
          })
            .then(resp => resp.json())
            .then(data => {

              resolve(data)
            })
        }
      }).catch(e => reject(e))
  })

}

// Clonage USUF TEMPORAIRE
export const usufTemporaireClone = (id) => {

  return new Promise((resolve, reject) => {

    fetch(`${restApiUri}/api/usufruit/findOneUsufruitFixe/${id}`, {
      method: "GET",
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${localStorage.getItem("token")}`
      }
    })
      .then(resp => resp.json())
      .then(data => {

        if (data.error) {
          reject(data?.error)
        } else {
          ;
          delete data['id']

          const usuf = JSON.parse(JSON.stringify(data));
          fetch(`${restApiUri}/api/usufruit/addUsufruitFixe`, {
            method: "POST",
            headers: {
              'Content-Type': 'application/json',
              Accept: 'application/json',
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
            body: JSON.stringify({
              identifiant_simulation: prefixeSimulationIssueDeDuplication(usuf.identifiant_simulation),
              clientId: data.clientId,
              commentaire: data.commentaire,
              terme_simulation: "fin",
              userId: data.userId,
              duree: data.duree,
              contre_valeur: data.contre_valeur,
              beneficiaire_temporaire: data.beneficiaire_temporaire,
              demembrement: data.demembrement,
              propriete_existant: data.propriete_existant,
              age_actuel: data.age_actuel,
              age_futur: data.age_futur,
              date_simulation: data.date_simulation
            })
          })
            .then(resp => resp.json())
            .then(data => {

              resolve(data)
            })
        }
      }).catch(e => reject(e))
  })

}

// Clonage Bilan patrimonial
export const bilanClone = (id) => {

  return new Promise((resolve, reject) => {

    fetch(`${restApiUri}/api/bilan_patrimonial/${id}`, {
      method: "GET",
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${localStorage.getItem("token")}`
      }
    })
      .then(resp => resp.json())
      .then(data => {

        if (data.error) {
          reject(data?.error)
        } else {
          ;
          delete data['id']

          const bilan = JSON.parse(JSON.stringify(data));

          fetch(`${restApiUri}/api/bilan_patrimonial`, {
            method: "POST",
            headers: {
              'Content-Type': 'application/json',
              Accept: 'application/json',
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
            body: JSON.stringify(cloneBilan({ ...data, situationDu: completeDate(now), identifiant_simulation: prefixeSimulationIssueDeDuplication(bilan.identifiant_simulation) }))
          })
            .then(resp => resp.json())
            .then(data => {

              resolve(data)
            })
        }
      })
      .catch(e => reject(e))
  })


}

// Clonage IR
export const fiscaliteClone = (id) => {

  return new Promise((resolve, reject) => {

    fetch(`${restApiUri}/api/fiscalite/findOne/${id}`, {
      method: "GET",
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${localStorage.getItem("token")}`
      }
    })
      .then(resp => resp.json())
      .then(data => {

        if (data.error) {
          reject(data?.error)
        } else {
          delete data['id']

          const fisc = JSON.parse(JSON.stringify(data));

          fetch(`${restApiUri}/api/fiscalite`, {
            method: "POST",
            headers: {
              'Content-Type': 'application/json',
              Accept: 'application/json',
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
            body: JSON.stringify(cloneFiscalite({ ...data, identifiant_simulation: prefixeSimulationIssueDeDuplication(fisc.identifiant_simulation) }))
          })
            .then(resp => resp.json())
            .then(data1 => {

              resolve(data1)
            })
        }

      })
      .catch(e => {
        reject(e)
      })
  })


}

// Clonage EMPRUNT
export const empruntClone = (id) => {
  return new Promise((resolve, reject) => {

    getEmprunt(id)
      .then(data => {

        if (data.error) {
          reject(data.error)
        } else {
          ;
          delete data['id'];

          const emp = JSON.parse(JSON.stringify(data));

          saveEmprunt(cloneEmprunt({ ...data, identifiant_simulation: prefixeSimulationIssueDeDuplication(emp.identifiant_simulation) }))
            .then(data1 => {
              resolve(data1)
            })
        }

      })
      .catch(e => {
        reject(e)
      })
  })

}

// Clonage Succession
export const successionClone = (id) => {

  return new Promise((resolve, reject) => {

    fetch(`${restApiUri}/api/succession/findOne/${id}`, {
      method: "GET",
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${localStorage.getItem("token")}`
      }
    })
      .then(resp => resp.json())
      .then(data => {

        if (data.error) {
          reject(data?.error)
        } else {
          delete data['id']

          const succ = JSON.parse(JSON.stringify(data));

          fetch(`${restApiUri}/api/succession`, {
            method: "POST",
            headers: {
              'Content-Type': 'application/json',
              Accept: 'application/json',
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
            body: JSON.stringify(cloneSuccession({ ...data, identifiant_simulation: prefixeSimulationIssueDeDuplication(succ.identifiant_simulation) }))
          })
            .then(resp => resp.json())
            .then(data => {

              resolve(data)

            })
        }
      })
      .catch(e => reject(e))
  })

}

// Clonage USUF ECO
export const usufEcoClone = (id) => {
  return new Promise((resolve, reject) => {
    getUsufEco(id)
    .then(response => response.json())
    .then(data => {
      if(data){
        if (data.error) {
          reject(data.error)
        }
        else {
          delete data['id'];

          const usuf = JSON.parse(JSON.stringify(data));
          saveUsufEco(cloneUsuf({ ...data, identifiant_simulation: prefixeSimulationIssueDeDuplication(usuf.identifiant_simulation) })).then(response => response.json()).then((data1) => {
            resolve(data1)
          })
        }
      }
      })
     .catch(error => reject(error))

  })
}

// Clonage IFI
export const ifiClone = (id) => {
  return new Promise((resolve, reject) => {
    getIfi(id).then(data => {
      if (data.error) { }
      else {
        delete data['id'];

        const ifi = JSON.parse(JSON.stringify(data));

        saveIfi(cloneIfi({ ...data, identifiant_simulation: prefixeSimulationIssueDeDuplication(ifi.identifiant_simulation) })).then((data1) => {
          resolve(data1)
        })
      }
    })

  })
}

// Clonage PV IMMO
export const pvImmoClone = async (id) => {
  return new Promise((resolve, reject) => {

    getSimulateur('/api/immobilier/findOne/', id)
      .then((data) => {
        if (data.error) { }
        else {
          const pvImmoState = {
            config: data.config,
            form1: data.form1,
            form2: data.form2,
            form3: data.form3
          }

          const infoSimu = {
            "identifiant_simulation": prefixeSimulationIssueDeDuplication(data.identifiant_simulation),
            "commentaire": data.commentaire,
            "terme_simulation": "fin",
            "clientId": data.client && data.client.id ? data.client.id : null,
            "userId": JSON.parse(localStorage.getItem('user')).id,
            "date_simulation": data.date_simulation
          }

          delete data['id'];

          savePvImmobilier(
            pvImmoState,
            infoSimu,
            () => {  },
            (response) => {
              resolve(response)
            },
            () => {
              reject(false)
            },
            () => { },
          )

        }
      })
  })
}

export const handleSimulationClone = (item) => {
  return new Promise((resolve, reject) => {

    if (item.category === 'PEA')
      peaClone(item.id).then(data => resolve(data)).catch(e => reject(e));
    else if (item.category === 'CT')
      ctClone(item.id).then(data => resolve(data)).catch(e => reject(e));
    else if (item.category === 'AV' || item.category === 'CP')
      avClone(item.id).then(data => resolve(data)).catch(e => reject(e));
    else if (item.category === 'IS')
      isClone(item.id).then(data => resolve(data)).catch(e => reject(e));
    else if (item.category === 'CMP')
      cmpClone(item.id).then(data => resolve(data)).catch(e => reject(e))
    else if (item.category === 'PF')
      pfClone(item.id).then(data => resolve(data)).catch(e => reject(e));
    else if (item.category === 'CS')
      cessionClone(item.id).then(data => resolve(data)).catch(e => reject(e));
    else if (item.category === 'DF')
      dfClone(item.id).then(data => resolve(data)).catch(e => reject(e));
    else if (item.category === 'DO')
      doClone(item.id).then(data => resolve(data)).catch(e => reject(e));
    else if (item.category === 'DE')
      deClone(item.id).then(data => resolve(data)).catch(e => reject(e));
    else if (item.category === 'USUFVIAGER')
      usufViagerClone(item.id).then(data => resolve(data)).catch(e => reject(e));
    else if (item.category === 'USUFFIXE')
      usufTemporaireClone(item.id).then(data => resolve(data)).catch(e => reject(e));
    else if (item.category === 'BILAN_PAT') {
      bilanClone(item.id).then(data => resolve(data)).catch(e => reject(e));
    }
    else if (item.category === 'SUCC') {
      successionClone(item.id).then(data => resolve(data)).catch(e => reject(e));
    }
    else if (item.category === 'FT') {
      fiscaliteClone(item.id).then(data => resolve(data)).catch(e => reject(e));
    }
    else if (item.category === 'EMP') {
      empruntClone(item.id).then(data => resolve(data)).catch(e => reject(e));
    }
    else if (item.category === 'USUFECO') {
      usufEcoClone(item.id).then(data => resolve(data)).catch(e => reject(e));
    }
    else if (item.category === 'IFI') {
      ifiClone(item.id).then(data => resolve(data)).catch(e => reject(e));
    }
    else if (item.category === 'IMMO') {
      console.log(item);
      pvImmoClone(item.id).then(data => resolve(data)).catch(e => reject(e));
    }
  })
}

export const handleClickConditionSaveSimulation = (currentLink) => {

  // Page des simulateurs BOX Cession de société
  const cessionPage = currentLink.includes(lienSimulateurCessionSociete);

  // Page des simulateurs BOX Donation et Succession
  const usufNPusufViagerPage = currentLink.includes(lienUsufruitViager);
  const usufNPusufTemporairePage = currentLink.includes(lienUsufruitTemporaire);
  const donsExoneresPage = currentLink.includes(lienDonsFamiliauxExoneres);
  const donationsEntreprisePage = currentLink.includes(lienDonationsEntreprise);
  const donationsOrdinairesPage = currentLink.includes(lienAutresDonations);
  const successionPage = currentLink.includes(lienSuccession);

  // Page des simulateurs BOX Patrimoine et Fiscalité
  const bilanPatrimonialPage = currentLink.includes(lienBilanPatrimonial);
  const fiscaliteRevenuPage = currentLink.includes(lienFiscaliteRevenus);
  const ifiPage = currentLink.includes(lienIFI);

  // Page des simulateurs BOX Immobilière
  const empruntPage = currentLink.includes(lienEmprunt);
  const usufruitEconomiquePage = currentLink.includes(lienUsufruitEconomique);
  const pvImmoPage = currentLink.includes(lienPlusValueImmobiliere);

  // Page correspondante à la Fiche Client
  const ficheClientPage = currentLink.includes(lienFicheClient);

  // Page des fiches thématiques
  const ftPEA = currentLink.includes(lienFicheThematiquePEA);
  const ftCT = currentLink.includes(lienFicheThematiqueCompteTitres);
  const ftAV = currentLink.includes(lienFicheThematiqueAssuranceVie);
  const ftCAPI = currentLink.includes(lienFicheThematiqueCapi);
  const ftIS = currentLink.includes(lienFicheThematiqueSocieteIS);
  const ftCS = currentLink.includes(lienFicheThematiqueCessionSociete);
  const ftDMTG = currentLink.includes(lienFicheThematiqueDMTG);
  const ftIFI = currentLink.includes(lienFicheThematiqueIFI);
  const ftPvImmo = currentLink.includes(lienFicheThematiqueRevLocatif);
  const ftClauseBeneficiaireAv = currentLink.includes(lienFicheThematiqueClauseBeneficiaireAV);

  const accueilPropositionFinancierePage = currentLink.includes('/accueil-proposition-financiere');
  const propositionFinancierePage = currentLink.includes('/proposition-financiere') && currentLink !== "/proposition-financiere-aide";

  return (bilanPatrimonialPage || ficheClientPage || cessionPage || usufNPusufViagerPage || usufNPusufTemporairePage || donsExoneresPage || donationsOrdinairesPage || donationsEntreprisePage || successionPage || fiscaliteRevenuPage || ftPEA || ftCT || ftAV || ftCAPI || ftIS || ftCS || ftDMTG || ftIFI || ftPvImmo || ftClauseBeneficiaireAv || empruntPage || usufruitEconomiquePage || ifiPage || pvImmoPage || ficheClientPage || accueilPropositionFinancierePage || propositionFinancierePage);
}

