import React, { useState, useEffect, useContext, useRef } from 'react';
import { Prompt, useLocation } from 'react-router';
import { lienAV, lienAideCessionSociete, lienAutresDonations, lienBilanPatrimonial, lienCAPI, lienCessionSociete, lienCompteTitres, lienDonationSuccession, lienDonationsEntreprise, lienDonsFamiliauxExoneres, lienEmprunt, lienFicheClient, lienFicheThematique, lienFicheThematiqueAssuranceVie, lienFicheThematiqueCapi, lienFicheThematiqueCessionSociete, lienFicheThematiqueClauseBeneficiaireAV, lienFicheThematiqueClauseBeneficiaireDemembreeAV, lienFicheThematiqueCompteTitres, lienFicheThematiqueDMTG, lienFicheThematiqueIFI, lienFicheThematiquePEA, lienFicheThematiqueRevLocatif, lienFicheThematiqueSocieteIS, lienFiscaliteRevenus, lienIFI, lienIS, lienImmobiliere, lienListeClient, lienListeSimulations, lienNoticeCalculCessionSociete, lienPEA, lienPageAccueilSimulabox, lienPatrimoineFiscalite, lienPlusValueImmobiliere, lienSimulateurCessionSociete, lienSuccession, lienUsufruitEconomique, lienUsufruitTemporaire, lienUsufruitViager } from '../../routes';
import { SimulateurContext } from '../../store/context/simulateur';
import ModalConfirmation from '../ModalConfirmation/ModalConfirmation';
import { desactivePageBlock } from '../../store/actions/simulateur';
import { useHistory } from 'react-router-dom';
import { LayoutContext } from '../../App2';

const ExitConfirmation = () => {
    const location = useLocation();
    const history = useHistory();
    const { simulateurState, simulateurDispatch } = useContext(SimulateurContext);
    const [isModalOpen, setIsModalOpen] = useState(false)
    const lastLocation = useRef(null);
    const { layoutState } = useContext(LayoutContext);
    const { footerButtons: { cs: cessionFooterButtons, usufNPusufViager, usufNPusufTemporaire, donsExoneres, donationsOrdinaires, donationsEntreprise, succession, ficheClient, bilanPatrimonial, fiscaliteRevenu, ft, emprunt, usufruitEconomique, ifi, pvImmo, accueilPropositionFinanciere, propositionFinanciere } } = layoutState;
    const [route, setRoute] = useState({
        to: location.pathname,
        from: location.pathname
    })
    const currentLink = location.pathname;
    const isUpdated = simulateurState?.comportementSortieSimulateurData?.updated;

    const [isPromptVisible, setPromptVisibility] = useState(true);

    const cessionPage = currentLink.includes(lienSimulateurCessionSociete);

    // Page des simulateurs BOX Donation et Succession
    const usufNPusufViagerPage = currentLink.includes(lienUsufruitViager);
    const usufNPusufTemporairePage = currentLink.includes(lienUsufruitTemporaire);
    const donsExoneresPage = currentLink.includes(lienDonsFamiliauxExoneres);
    const donationsEntreprisePage = currentLink.includes(lienDonationsEntreprise);
    const donationsOrdinairesPage = currentLink.includes(lienAutresDonations);
    const successionPage = currentLink.includes(lienSuccession);

    // Page des simulateurs BOX Patrimoine et Fiscalité
    const bilanPatrimonialPage = currentLink.includes(lienBilanPatrimonial);
    const fiscaliteRevenuPage = currentLink.includes(lienFiscaliteRevenus);
    const ifiPage = currentLink.includes(lienIFI);

    // Page des simulateurs BOX Immobilière
    const empruntPage = currentLink.includes(lienEmprunt);
    const usufruitEconomiquePage = currentLink.includes(lienUsufruitEconomique);
    const pvImmoPage = currentLink.includes(lienPlusValueImmobiliere);

    // Page correspondante à la Fiche Client
    const ficheClientPage = currentLink.includes(lienFicheClient);

    // Page des fiches thématiques
    const ftPEA = currentLink.includes(lienFicheThematiquePEA);
    const ftCT = currentLink.includes(lienFicheThematiqueCompteTitres);
    const ftAV = currentLink.includes(lienFicheThematiqueAssuranceVie);
    const ftCAPI = currentLink.includes(lienFicheThematiqueCapi);
    const ftIS = currentLink.includes(lienFicheThematiqueSocieteIS);
    const ftCS = currentLink.includes(lienFicheThematiqueCessionSociete);
    const ftDMTG = currentLink.includes(lienFicheThematiqueDMTG);
    const ftIFI = currentLink.includes(lienFicheThematiqueIFI);
    const ftPvImmo = currentLink.includes(lienFicheThematiqueRevLocatif);
    const ftClauseBeneficiaireAv = currentLink.includes(lienFicheThematiqueClauseBeneficiaireAV);
    const ftClauseBeneficiaireDemembreeAv = currentLink.includes(lienFicheThematiqueClauseBeneficiaireDemembreeAV);

    const accueilPropositionFinancierePage = currentLink.includes('/accueil-proposition-financiere');
    const propositionFinancierePage = currentLink.includes('/proposition-financiere') && currentLink !== "/proposition-financiere-aide";

    // Le FOOTER sur la page d'aide et Notice de la cession de société ne doit pas avoir de picto.
    const buttons = cessionPage ?
    cessionFooterButtons :
    usufNPusufViagerPage ? usufNPusufViager :
      usufNPusufTemporairePage ? usufNPusufTemporaire :
        donsExoneresPage ? donsExoneres :
          donationsOrdinairesPage ? donationsOrdinaires :
            donationsEntreprisePage ? donationsEntreprise :
              ficheClientPage ? ficheClient :
                bilanPatrimonialPage ? bilanPatrimonial :
                  successionPage ? succession :
                    fiscaliteRevenuPage ? fiscaliteRevenu :
                      ftPEA ? ft.pea :
                        ftCT ? ft.ct :
                          ftAV ? ft.av :
                            ftCAPI ? ft.capi :
                              ftIS ? ft.is :
                                ftCS ? ft.cs :
                                  ftDMTG ? ft.dmtg :
                                    empruntPage ? emprunt :
                                      usufruitEconomiquePage ? usufruitEconomique :
                                        ifiPage ? ifi :
                                          ftIFI ? ft.ifi :
                                            ftPvImmo ? ft.pvImmo :
                                              ftClauseBeneficiaireAv ? ft.clauseBeneficiaireAv :
                                                ftClauseBeneficiaireDemembreeAv ? ft.clauseBeneficiaireDemembreeAv :
                                                  pvImmoPage ? pvImmo :
                                                    accueilPropositionFinancierePage ? accueilPropositionFinanciere :
                                                      propositionFinancierePage ? propositionFinanciere :
                                                        simulateurState.buttons;

    // A chaque fois que l'URL du navigateur change
    useEffect(() => {
        setRoute((prevState) => {
            return {
                from: prevState.to,
                to: location.pathname
            }
        })
    }, [location?.pathname])

    const shouldShowPrompt = (currentLocation) => {
        // Votre logique pour déterminer si vous devez afficher la boîte de dialogue de confirmation.
        // Par exemple, vérification si des données non sauvegardées existent.
        const pathnamesWithoutActions = [
            lienPageAccueilSimulabox, // Lien de la page d'accueil
            lienListeSimulations, // Lien de la liste des simulations
            '/accueilSCP', // Lien de la page d'accueil de la Box Financière
            '/simulateurs', // Lien de la page d'accueil des simulateurs autonomes
            lienCessionSociete, // Lien de la page d'accueil de la Box Cession de société
            lienNoticeCalculCessionSociete, // Lien de la notice de calcul de la Cession de société
            lienAideCessionSociete, // Lien de la page d'aide de la Cession de société
            lienDonationSuccession, // Lien de la page d'accueil de la Box Donation et Succession
            lienPatrimoineFiscalite, // Lien de la page d'accueil de la Box Patrimoine et Fiscalité
            lienImmobiliere, // Lien de la page d'accueil de la Box Immobilière
            lienListeClient, // Lien de la liste des Clients
            lienFicheClient,
            // "/comparaisons/formulaire",
            // "/comparaisons/resultat_financier"
        ]

        let isComparaison = false;

        const linksComparaison = ['/comparaisons/formulaire', '/comparaisons/resultat_transmission', '/comparaisons/resultat_financier']

        if (linksComparaison.includes(String(location?.pathname)) && linksComparaison.includes(currentLocation.pathname)) {
          isComparaison = true;
        }

        const conditionAction = isUpdated &&
                                !isComparaison &&
                                !pathnamesWithoutActions.includes(location?.pathname) &&
                                !location?.pathname.includes(lienFicheThematique) &&
                                !location?.pathname.includes(lienFicheClient); // && !lastLocation.current?.pathname.includes(lienFicheClient);

        // if (conditionAction) {
        //     const unblock = history.block((loc, action) => {
        //         const { pathname: nextPathname } = loc;
        //         const lienComparaisonStructures = '/comparaisons/'
        //         const liens = [lienPEA, lienCompteTitres, lienAV, lienCAPI, lienIS, lienComparaisonStructures];
        //         let lien = null;
        //         const pathname = location?.pathname;

        //         console.log(nextPathname);

        //         if (pathname.includes(lienPEA)) lien = lienPEA
        //         else if (pathname.includes(lienCompteTitres)) lien = lienCompteTitres
        //         else if (pathname.includes(lienAV)) lien = lienAV
        //         else if (pathname.includes(lienCAPI)) lien = lienCAPI
        //         else if (pathname.includes(lienIS)) lien = lienIS
        //         else if (pathname.includes(lienComparaisonStructures)) lien = lienComparaisonStructures
        //         else if (pathname.includes(lienFicheClient)) lien = lienFicheClient

        //         const conditionParticuliere = !(nextPathname.includes(lien) && route.to.includes(lien));
        //         const conditionBlocage = pathname.includes(lien) ? conditionParticuliere : true;

        //         return conditionBlocage;
        //     })

        //     return () => {
        //         unblock()
        //     }
        // }

        return conditionAction;
    };

    useEffect(() => {
      setPromptVisibility(true);
    }, [])

    const handleBlockedNavigation = (location, desactive) => {
        setPromptVisibility(true);
        lastLocation.current = location;
        if (shouldShowPrompt(location)) {
            setIsModalOpen(true);
            return false;
        }
        return true;
    };

    const handleModalConfirm = (type) => {
      setPromptVisibility(false);
      simulateurDispatch(desactivePageBlock());
      if (type === "OK") {
        if (lastLocation.current) {
          // window.location.href = lastLocation.current.pathname;
          setTimeout(() => {
            history.push({
              pathname: lastLocation.current.pathname,
              search: lastLocation.current.search,
            })
          }, 500);
        }
      } else {
        if (buttons.length > 0) {
          for (const button of buttons) {
            if (button.type === "ADD" && button.text === "Enregistrer") {
              button.onClick();
              setTimeout(() => {
                history.push({
                  pathname: lastLocation.current.pathname,
                  search: lastLocation.current.search,
                })
              }, 1000);
            }
          }
        }
      }
    };

    useEffect(() => {
      const handleBeforeUnload = (event) => {
        const message = "Are you sure you want to leave? Your changes may not be saved.";
        event.returnValue = message; // Standard for most browsers
        return message; // For some older browsers
      };

      if (simulateurState?.comportementSortieSimulateurData?.updated) {
        // Add the event listener when the component mounts
        window.addEventListener('beforeunload', handleBeforeUnload);
      } else {
        window.removeEventListener('beforeunload', handleBeforeUnload);
      }

      // Remove the event listener when the component unmounts
      return () => {
        window.removeEventListener('beforeunload', handleBeforeUnload);
      };
    }, [simulateurState?.comportementSortieSimulateurData?.updated]);

    return (
        <>
            <Prompt when={true} message={handleBlockedNavigation} />
            <ModalConfirmation
                closeModal={false}
                visible={isModalOpen}
                setVisible={setIsModalOpen}
                color='white'
                background='#7A86A1'
                onOKClick={() => handleModalConfirm("OK")}
                onNOKClick={handleModalConfirm}
                buttonCloseText={'Enregistrer et quitter'}
                buttonValidationText={'Quitter sans enregistrer'}
                doNotQuit={true}
                text={<div>Avant de quitter cette simulation, assurez-vous d’enregistrer vos modifications.
                  Si vous quittez maintenant, toutes les modifications non enregistrées seront perdues.
                  Êtes-vous sûr de vouloir continuer sans enregistrer ?</div>}
            />
        </>
    )
}

export default ExitConfirmation;
